import {
  Box, CardMedia, Link, Stack, Typography, styled
} from "@mui/material";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useEffect, useMemo, useRef, useState } from "react";
import { GenericEmbed, PopupButton } from "@typeform/embed-react";
import { Navigate } from "react-router-dom";
import { useBreakpointSm } from "../utils/useStyleHooks";
import { EGradientTxt } from "./homepageTool/componted";
import { createRem } from "../utils/rem";
import EurekaProduct from "./homepageTool/eurekaProduct";
import EurekaVideos from "./homepageTool/eurekaVideos";
import EurekaDynamic from "./homepageTool/eurekaDynamic";
import EurekaCustomer from "./homepageTool/eurekaCustomer";
import EurekaMarketing from "./homepageTool/eurekaMarketing";
import EurekaFooder from "./homepageTool/eurekaFooder";
import { useGetUser } from "../hooks/userHook";
import EurekaQuestions from "./homepageTool/eurekaQuestions";
import EurekaModuleEig from "./homepageTool/eurekaModuleEig";

const remChange = createRem(true);
remChange();

const InheritTxt = styled("p")({
  fontFamily: "inherit", fontSize: "inherit", lineHeight: "inherit"
});

export default function HomePageMain() {
  const isSM = useBreakpointSm();
  const auth = useGetUser();
  const popupBtnRef = useRef<GenericEmbed>();
  const [hiddenPlay, setHiddenPlay] = useState(false);
  const [openlink, setOpenlink] = useState(false);

  const openFormEmbed = () => {
    popupBtnRef.current?.open();
  }

  const componentSx = useMemo(()=>({
    "bgStyle1": isSM ? {
      width:"5.5rem", height:"5.5rem", right:"-2.4rem", top:"4.3rem",
      background: "#5D49D2", filter: "blur(5rem)"
    }:{
      width:"6rem", height:"6rem", right:"-1.24rem", top:"4.9rem",
      background: "#5D49D2", filter: "blur(6rem)"
    },
    "bgStyle2": isSM ? {
      
    }:{
      width:"5.8rem", height:"5.8rem", left:"6.82rem", top:"11.8rem",
      background: "#5D49D2", filter: "blur(10rem)"
    },
    "bgStyle3": isSM ? {
      
    }:{
      width:"6.5rem", height:"6.5rem", left:"1.4rem", top:"20.1rem",
      background: "#5D49D2", filter: "blur(4rem)"
    },
    "bgStyle4": isSM ? {
      
    }:{
      width:"1.3rem", height:"1.3rem", left:"2.4rem", top:"30rem",
      background: "#5D49D2", filter: "blur(0rem)", borderRadius: "50%"
    },
  }),[isSM]);

  if(auth) return <Navigate to="/" />;

  const checkedPlay = () => {
    const video = document.getElementById("eureka-pages-video") as HTMLVideoElement;
    if(video.paused){
      video.play();
    }else{
      video.pause();
    }
  }

  const videoPlayChange = () => {
    setHiddenPlay(true);

  }
  const videoPauseChange = () => {
    setHiddenPlay(false);
  }

  return (
    <Box id="eureka-parentall" sx={{
      overflowY: "auto", overflowX: "hidden",
      bgcolor: "#0B142A",
      width: "100%", height: "100%", position:"relative",
    }}>
      <PopupButton id="UEFJkLW8" style={{ display: "none" }} embedRef={popupBtnRef}>
        open click
      </PopupButton>
      <Box sx={{
        ...componentSx.bgStyle1, position:"absolute", zIndex:"0",
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle2, position:"absolute", zIndex:"0",
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle3, position:"absolute", zIndex:"0",
      }}></Box>

      <Box sx={{
        maxWidth: "19.2rem", width: "100%",
        p: isSM ? "0.16rem 0.18rem" : "0.22rem",
        m: "0 auto",
      }}>
        <Box sx={{
          overflow: "hidden", borderRadius: "0.3rem", position: "relative",
          background: "linear-gradient(170deg, rgba(38, 44, 120, 0.52) 12%, rgba(25, 39, 86, 0.52) 100%)",
          backdropFilter: "blur(0.45rem)"
        }}>
          <Stack direction="row" sx={{
            p: isSM ? "0.24rem" : "0.3rem 1.22rem",
            justifyContent: "space-between",
            position: "relative", zIndex: "2"
          }} component="header">
            <Box width="3rem" pt={isSM ? "0" : "0.1rem"}>
              <CardMedia component="img" src="/logoPicture/waywardWhile.png"
                sx={{ width: isSM ? "1.36rem" : "1.96rem" }} alt="Eureka-AI logo" />
            </Box>
            { isSM ? (
              <Box sx={{ position: "relative" }}>
                <CardMedia component="img" src="/eurekaImage/phoneMore.png"
                  sx={{ width: "0.32rem" }}
                  onClick={()=> setOpenlink(!openlink) } alt="Eureka sign in" />
                <Stack sx={{
                  position: "absolute", top: "0.36rem", right: "0rem",
                  bgcolor: "#5e44f5", borderRadius: "0.1rem",
                  boxShadow: "0 0 0.2rem #5e44f5", p: "0.1rem",
                  width: "1rem", textAlign: "center",
                  display: openlink ? "flex" : "none"
                }}>
                  <Link sx={{
                    fontSize: "0.16rem", color: "#FFFFFF", fontFamily: "Satoshi"
                  }} href="/account/login">
                    Sign In
                  </Link>
                </Stack>
              </Box>
            ) : (
              <Stack width="3rem" direction="row" justifyContent="space-between"
                alignItems="center">
                <Link sx={{
                  fontSize: "0.18rem", fontFamily: "Satoshi", color: "#FFFFFF",
                  fontWeight: "400"
                }} href="/account/login">
                  Sign In
                </Link>
                <Box sx={{width: "0.01rem", height: "100%", bgcolor: "#656565"}}></Box>
                <Stack direction="row" sx={{
                  alignItems: "center", p: "0.25rem 0.28rem", cursor: "pointer",
                  backgroundImage: "url(/eurekaImage/pageStarted.png)",
                  backgroundSize: "100% 100%",
                }} onClick={openFormEmbed}>
                  <Typography sx={{
                    fontFamily: "Satoshi", fontSize: "0.18rem", fontWeight: "400", 
                    lineHeight: "0.18rem", mr: "0.1rem"
                  }}>
                    Get Started
                  </Typography>
                  <ArrowForwardRoundedIcon sx={{ fontSize: "0.18rem", color: "#FFFFFF" }} />
                </Stack>
              </Stack>
            ) }
          </Stack>

          <Stack direction={isSM ? "column" : "row"}>
            <Stack
              sx={{
                position: "relative",
                width: isSM ? "100%" : "9.8rem",
                zIndex: "3", justifyContent: "center",
                maxWidth: "100%",
                p: isSM ? "0.7rem 0.06rem 0 0.2rem" : "1.95rem 0 3rem 1.2rem"
              }}>
              { isSM ? (
                <Typography sx={{
                  fontFamily: "ClashDisplay", fontSize: "0.44rem",
                  lineHeight: "0.44rem",
                  letterSpacing: "-0.1px",
                }} component="h1">
                  <span>Explode Your</span><br />
                  <span>Brand Reach</span><br />
                  <span>with </span><EGradientTxt >AI-Powered </EGradientTxt><br />
                  <span>Visuals</span>
                </Typography>
              ) : (
                <Typography sx={{
                  fontFamily: "ClashDisplay", fontSize: "0.76rem",
                  lineHeight: "0.76rem",
                  letterSpacing: "-0.1px"
                }} component="h1">
                  <span>Explode Your Brand</span><br />
                  <span>Reach with </span><EGradientTxt >AI-Powered</EGradientTxt><br />
                  <span>Visuals</span>
                </Typography>
              )}
              <Stack sx={{
                fontSize: isSM ? "0.16rem" : "0.2rem",
                lineHeight: isSM ? "0.22rem" : "0.3rem",
                mt: "0.2rem",
                width: isSM ? "100%" : "8.3rem",
                textAlign: "left",
                p: isSM ? "0 0.1rem 0 0" : "0"
              }}>
                <InheritTxt>
                  Generate high-converting videos and slideshows in minutes, not days.
                  Scale your content creation, crush your competition, and skyrocket sales.
                </InheritTxt>
              </Stack>
              <Stack direction="row" mt={isSM ? "0.32rem" : "0.26rem"}>
                <Stack direction="row" sx={{
                  alignItems: "center", p: isSM ? "0.18rem 0.22rem" : "0.25rem 0.28rem",
                  cursor: "pointer",
                  backgroundImage: "url(/eurekaImage/pageStarted2.png)",
                  backgroundSize: "100% 100%",
                  filter: "drop-shadow(-0.08rem 0.09rem 0.48rem #7422D8B5)"
                }} onClick={openFormEmbed}>
                  <Typography sx={{
                    fontFamily: "Satoshi", fontSize: isSM ? "0.14rem" : "0.18rem",
                    lineHeight: isSM ? "0.14rem" : "0.18rem", mr: "0.1rem"
                  }}>
                    Get Started
                  </Typography>
                  <ArrowForwardRoundedIcon sx={{ fontSize: "0.18rem", color: "#FFF" }} />
                </Stack>
              </Stack>
            </Stack>
            <Box sx={{
              flexGrow: 1, position: "relative",
            }}>
              <Box sx={{
                overflow: "hidden", position: "absolute", cursor: "pointer",
                borderRadius: isSM ? "0.1rem" : "0.2rem",
                zIndex: "1",
                top: isSM ? "0.5rem" : "0.46rem",
                left: isSM ? "1.3rem" : "2.34rem",
                width: isSM ? "1.24rem" : "3.1rem",
                height: isSM ? "2.64rem" : "6.6rem",
                bgcolor: "#000000"
              }} onClick={checkedPlay}>
                
              { !hiddenPlay && (
                <Box sx={{
                  p: isSM ? "0.06rem" : "0.12rem", bgcolor: "#ffffff3d", borderRadius: "50%",
                  position: "absolute", top: "50%", left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 8, cursor: "pointer",
                  "&:hover .MuiStack-root": {
                    boxShadow: "0 0 0.4rem 0.1rem #ffffff",
                  }
                }}>
                  <Box sx={{ p: isSM ? "0.06rem" : "0.12rem", bgcolor: "#ffffff3d", borderRadius: "50%" }}>
                    <Stack sx={{
                      alignItems: "center", justifyContent: "center", borderRadius: "50%",
                      bgcolor: "#FFFFFF",
                      width: isSM ? "0.4rem" : "0.78rem",
                      height: isSM ? "0.4rem" : "0.78rem",
                      transition: "box-shadow 0.2s",
                    }}>
                      <PlayArrowRoundedIcon sx={{
                        color: "#000000", fontSize: isSM ? "0.2rem" : "0.4rem"
                      }} />
                    </Stack>
                  </Box>
                </Box>
              ) }
                <CardMedia component="video" src="/eurekaImage/pageVideo.mp4"
                  id="eureka-pages-video"
                  sx={{ position: "relative", width: "100%", height: "100%", zIndex: 2 }}
                  onPlay={videoPlayChange}
                  onPause={videoPauseChange} />
                <CardMedia component="img" src="/eurekaImage/pageVideoThumb.jpg"
                  sx={{
                    position: "absolute", maxWidth: "100%", maxHeight: "100%", zIndex: 1,
                    top: "50%", left: "0", transform: "translateY(-50%)"
                  }} alt="Publicize Image" />
              </Box>
              <CardMedia component="img" src="/eurekaImage/pagestate1.png"
                sx={{
                  position: isSM ? "relative" : "absolute",
                  bottom: "0", right: "0",
                  width: isSM ? "100%" : "10rem", 
                }} alt="Eureka publicize Image" />
              <Box sx={{
                position: "absolute", bottom: "0", right: "0",
                background: "linear-gradient(350deg, #171F4A 17%, rgba(0,0,0,0) 62%)",
                width: isSM ? "2.8rem" : "7.08rem",
                height: isSM ? "1rem" : "2.48rem"
              }}></Box>
            </Box>
          </Stack>
        </Box>
      </Box>
      <EurekaProduct />
      <EurekaVideos openFormEmbed={openFormEmbed} />
      <EurekaDynamic />
      <EurekaCustomer />
      <EurekaMarketing />
      <EurekaQuestions />
      <EurekaModuleEig openFormEmbed={openFormEmbed} />

      <EurekaFooder />

    </Box>
  );
}