
const envApiBase = {
  // local: process.env.REACT_APP_API_URL_LOCAL as string,
  local: '/api/v1/',
  prod: '/api/v1/',
  test: '/api/v1/'
};
const envApiAuthBase = {
  // local: process.env.REACT_APP_ADMIN_API_URL_LOCAL as string,
  local: '/api/v1/auth/',
  prod: '/api/v1/auth/',
  test: '/api/v1/auth/'
};

export const deployedEnv = () => {
  switch (window.location.hostname) {
    case '52.233.82.211':
    case 'wayward.video':
      return 'prod';
    case "127.0.0.1":
      return "test";
    default:
      return 'local';
  }
};

export const envResolverAPIURL = () => {
  const env = deployedEnv();
  switch (env) {
    case 'prod':
    case 'test':
      return window.location.origin + envApiBase[env];
    default:
      return envApiBase['local'];
  }
};

export const envResolverAuthAPIURL = () => {
  const env = deployedEnv();
  switch (env) {
    case 'prod':
    case 'test':
      return window.location.origin + envApiAuthBase[env];
    default:
      return envApiAuthBase['local'];
  }
};

export const isEnvProduction = () => {
  return deployedEnv() === 'prod' ? true : false;
};

export const mediaRequestUrl = () => {
  
  return deployedEnv() === 'prod' ?
    window.location.origin :
    // process.env.REACT_APP_PICTURE_LOCAL_URL;
    // "http://192.168.31.154:18081";
    "https://52.233.82.211";
}
