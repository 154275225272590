import { styled } from "@mui/material";

export const GradientTxt = styled("span")({
  background: "linear-gradient(270deg, #06BFB6 20%, #7D6DD9 75%);",
  backgroundClip: "text", color: "transparent",
  fontFamily: "inherit", fontSize: "inherit", lineHeight: "inherit"
});

export const EGradientTxt = styled("span")({
  background: "linear-gradient(270deg, #5034E9 3%, #7e69ff 60%)",
  backgroundClip: "text", color: "transparent",
  fontFamily: "inherit", fontSize: "inherit", lineHeight: "inherit"
});