import {
  Box, CardMedia, Link, Stack, styled, Typography
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { EGradientTxt } from "./componted";
import { useBreakpointSm } from "../../utils/useStyleHooks";
import { swiperVideoList } from "./aTemplate";

const DivBox = styled("div")({})

export default function EurekaVideos({
  openFormEmbed
}:{
  openFormEmbed: ()=>void
}){
  const isSM = useBreakpointSm();
  const swiperRef = useRef<any | null>(null);

  const mediaList = useMemo(() => swiperVideoList, []);
  
  const socialList = useMemo(()=>[
    {id: "1", text: "Create professional-quality videos with captivating visuals, persuasive scripts, and a natural-sounding voiceover." },
    {id: "2", text: "Ideal for social media ads, product demos, and explainer videos." },
  ],[]);

  const createSwiper = () => {
    var swiper = new window.Swiper(".swiper-container", {
      slidesPerView: 3,
      spaceBetween: isSM ? 20 : 0,
      centeredSlides: true,
      loop: true,
      on: {
        click: function(){
          const _t = this as any;
          if(_t.clickedIndex === _t.activeIndex){
            let checked = _t.slides[_t.activeIndex];
            let media = checked.querySelector("video") as HTMLVideoElement;
            let playIcon = checked.querySelector(".slide-playing-icons") as HTMLElement;
            if(media.paused){
              media.play();
              playIcon.style.display = "none";
            }else{
              media.pause();
              playIcon.style.display = "block";
            }
            return;
          }
          _t.slides.forEach( (slide: any) => {
            let media = slide.querySelector("video") as HTMLVideoElement;
            let playIcon = slide.querySelector(".slide-playing-icons") as HTMLElement;
            if(!media.paused){
              media.pause();
              playIcon.style.display = "block";
            }
          } );
          if(_t.clickedIndex > _t.activeIndex){
            _t.slideNext()
          }else{
            _t.slidePrev()
          }
        }
      }
    });
    swiperRef.current = swiper;
  };

  useEffect(() => {
    createSwiper();
    return () => {
      swiperRef.current?.destroy();
    }
  }, [isSM]);

  return (
    <Stack direction={ isSM ? "column-reverse" : "row"}
      m={isSM ? "0.6rem auto 0" : "1.8rem auto 0"} maxWidth="19.2rem">
      <Box flexGrow="1" overflow="hidden" position="relative"
        width={isSM ? "7rem" : "auto"}
        m={isSM ? "0.8rem 0 0 -1.3rem" : "0"}>
      
      <CardMedia component="img" src="/eurekaImage/tklogo.png"
        sx={{
          position: "absolute", top: 0, left: isSM ? "1.8rem" : "2.4rem",
          width: isSM ? "0.46rem" : "0.66rem", zIndex: 2,
        }} alt="tiktok logo" />
      <CardMedia component="img" src="/eurekaImage/inslogo.png"
        sx={{
          position: "absolute",
          bottom: "0.4rem", right: isSM ? "2.2rem" : "2.9rem",
          width: isSM ? "0.46rem" : "0.66rem", zIndex: 2,
        }} alt="instagram logo" />
        <DivBox className="swiper-container">
          <DivBox className="swiper-wrapper" 
            sx={{
              "& .swiper-slide":{
                transition: "all 0.3s",
                transform: "scale(0.8)",
                opacity: 0.5
              },
              "& .swiper-slide-active,.swiper-slide-duplicate-active":{
                transform: "scale(1)",
                opacity: 1
              }
            }}>
            {mediaList.map((media) => (
              <DivBox key={media.id} className="swiper-slide"
                sx={{ cursor: "pointer", borderRadius: "0.3rem", overflow: "hidden" }}>

                <Box sx={{
                  p: "0.08rem", bgcolor: "#ffffff3d", borderRadius: "50%",
                  position: "absolute", top: "50%", left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 8, cursor: "pointer",
                  "&:hover .MuiStack-root": {
                    boxShadow: "0 0 0.4rem 0.1rem #ffffff",
                  }
                }} className="slide-playing-icons">
                  <Box sx={{ p: "0.08rem", bgcolor: "#ffffff3d", borderRadius: "50%" }}>
                    <Stack sx={{
                      alignItems: "center", justifyContent: "center", borderRadius: "50%",
                      bgcolor: "#08153A", width: "0.48rem", height: "0.48rem",
                      transition: "box-shadow 0.2s",
                    }}>
                      <PlayArrowRoundedIcon sx={{ color: "#ffffff", fontSize: "0.32rem" }} />
                    </Stack>
                  </Box>
                </Box>

                <CardMedia component="video" loop src={media.url} playsInline
                  preload="auto"
                  sx={{
                    position: "absolute", top: 0, left: 0,
                    width: "100%", height: "100%", zIndex: 1,
                  }} />
                <CardMedia component="img" src={media.thumb} alt="Eureka AI Video"
                  sx={{ maxWidth: "100%", maxHeight: "100%" }} />
              </DivBox>
            ))}
          </DivBox>
        </DivBox>
      </Box>
      <Box sx={{
        width: isSM ? "100%" : "9.4rem",
        p: isSM ? "0 0.2rem" : "0.5rem 1.3rem 0 0.4rem",
        flexShrink: 0,
      }}>
        <Typography sx={{
          fontFamily: "ClashDisplay",
          fontSize: isSM ? "0.32rem" : "0.76rem",
          lineHeight: isSM ? "0.32rem" : "0.76rem",
          letterSpacing: "-0.1px"
        }} component="h2">
          <span>Effortless </span><EGradientTxt>Product</EGradientTxt><br />
          <EGradientTxt>Videos </EGradientTxt><span>That Perform</span>
        </Typography>
        <Box mt="0.3rem" position="relative">
          <Box sx={{
            position: "absolute", top: "0.06rem", left: "0.12rem",
            width: "0.01rem", height: isSM ? "90%" : "100%",
            bgcolor: "#8A8BA3"
          }}></Box>
          {socialList.map((item,index)=>(
            <Stack key={item.id} direction="row" alignItems="flex-start"
              pb={isSM ? "0.2rem" : "0.34rem"}
              position="relative" zIndex="1">
              <Box p="0.04rem" bgcolor="#FFFFFF38" borderRadius="50%">
                <Box sx={{
                  width: "0.16rem", height: "0.16rem", borderRadius: "0.08rem",
                  background: "linear-gradient(285deg, rgb(142,124,245), rgb(112,89,239))"
                }}></Box>
              </Box>
              <Box sx={{ ml: isSM ? "0.15rem" : "0.2rem" }}>
                <Typography sx={{
                  fontFamily: "Satoshi",
                  fontSize: isSM ? "0.16rem" : "0.2rem",
                  lineHeight: isSM ? "0.24rem" : "0.3rem", 
                }}>
                  {item.text}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Box>

        <Stack direction="row" mt="0.34rem">
          <Stack direction="row" sx={{
            alignItems: "center", p: isSM ? "0.18rem 0.22rem" : "0.25rem 0.28rem",
            backgroundImage: "url(/eurekaImage/pageStarted2.png)",
            backgroundSize: "100% 100%", cursor: "pointer",
            filter: "drop-shadow(-0.08rem 0.09rem 0.48rem #7422D8B5)"
          }} onClick={openFormEmbed}>
            <Typography sx={{
              fontFamily: "Satoshi", fontSize: isSM ? "0.14rem" : "0.18rem",
              lineHeight: isSM ? "0.14rem" : "0.18rem", mr: "0.1rem"
            }}>
              Get Started
            </Typography>
            <ArrowForwardRoundedIcon sx={{ fontSize: "0.18rem", color: "#FFF" }} />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}