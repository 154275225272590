import { Box, CardMedia, Stack, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { productToVideoList } from "./aTemplate";
import { useBreakpointSm } from "../../utils/useStyleHooks";

export default function EurekaProduct() {
  const isSM = useBreakpointSm();
  const stepLists = useMemo(() => productToVideoList, [])

  return (
    <Stack alignItems="center" m={isSM ? "0.6rem auto 0" : "1rem auto 0"}
      maxWidth={isSM ? "3.2rem" : "19.2rem"}>
      <Typography sx={{
        fontFamily: "ClashDisplay", textAlign: "center",
        fontSize: isSM ? "0.32rem" : "0.76rem",
        lineHeight: isSM ? "0.32rem" : "0.76rem",
      }} component="h2">
        <span>From Product to Videos Within A</span><br />
      </Typography>
      <Typography sx={{
        fontFamily: "ClashDisplay", textAlign: "center",
        fontSize: isSM ? "0.32rem" : "0.76rem",
        lineHeight: isSM ? "0.32rem" : "0.76rem",
      }} component="h2">
        <span>Few Clicks In </span>
        <span style={{ color: "#705AEF" }}>Just a Few Clicks</span>
      </Typography>
      <Stack direction={isSM ? "column" : "row"}
        mt={isSM ? "0.54rem" : "1.05rem"}
        gap={isSM ? "0.48rem" : "0.6rem"} width="100%"
        justifyContent="center" position="relative">
        <Box sx={{
          width: "100%", height: "0.01rem",
          position: "absolute", top: "1.8rem", left: 0,
          background: "linear-gradient(90deg, rgba(26, 30, 73, 0.5) 0%, #7b7b7b 50%, rgba(26, 30, 73, 0.5) 100%)",
          display: isSM ? "none" : "block"
        }}></Box>
        {stepLists.map((step, index) => (
          <Box key={step.id} sx={{ position: "relative", zIndex: 1 }}>
            <Typography sx={{
              background: "linear-gradient(280deg, #5034E9 -28%, #9B8BF9 112%)",
              fontSize: isSM ? "0.16rem" : "0.2rem",
              lineHeight: isSM ? "0.36rem" : "0.46rem",
              top: isSM ? "-0.18rem" : "-0.2rem",
              left: isSM ? "0.3rem" : "0.4rem",
              p: "0 0.16rem",
              fontFamily: "ClashDisplay", borderRadius: "0.36rem",
              position: "absolute",
            }}>
              {step.id}
            </Typography>
            <Stack alignItems="center" justifyContent="center" sx={{
              width: isSM ? "3.2rem" : "3.6rem",
              height: isSM ? "3.2rem" : "3.6rem",
              borderRadius: "0.36rem",
              backgroundImage: "url(/eurekaImage/product_bg.png)",
              backgroundSize: "100% 100%",
            }}>
              <CardMedia component="img" src={step.image}
                sx={{ width: "100%" }} loading="lazy" alt="Create AI Video Step" />
            </Stack>
            <Typography sx={{
              fontFamily: "Satoshi",
              fontSize: isSM ? "0.2rem" : "0.26rem",
              lineHeight: isSM ? "0.24rem" : "0.32rem",
              m: isSM ? "0.26rem 0 0 0.1rem" : "0.3rem 0 0",
              width: isSM ? "3.2rem" : "3.6rem"
            }}>
              <span>{step.desc}</span><br />
              <span>{step.desc2}</span>
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}