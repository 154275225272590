export function createRem(isHomePage: boolean) {
  let pathname = window.location.pathname;
  let isAccount = pathname.includes("/account");
  
  return function(){
    var screenWidth = window.innerWidth;
    let threshold = screenWidth / 100;
    var rootFontSize = 100;
    if (screenWidth <= 600) {
      threshold = 4.3;
      rootFontSize = Math.round( screenWidth / threshold ); 
    }else if(isHomePage && !isAccount){
      if(screenWidth <= 1280) screenWidth = 1280;
      if(screenWidth >= 1920) screenWidth = 1920;
      threshold = 19.2;
      rootFontSize = Math.floor( screenWidth / threshold );
    }
    document.documentElement.style.fontSize = rootFontSize + 'px';
  };
}