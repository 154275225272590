export const productToVideoList = [
  {
    id: "Step 1",
    desc: "Tell Us About Your",
    desc2: "Product (Link or Details)",
    image: "/eurekaImage/product1.png"
  }, {
    id: "Step 2",
    desc: "AI Generates Engaging",
    desc2: "Ideas & Scripts",
    image: "/eurekaImage/product2.png"
  }, {
    id: "Step 3",
    desc: "Produce Videos with AI",
    desc2: "Avatars or Faceless",
    image: "/eurekaImage/product3.png"
  }, {
    id: "Step 4",
    desc: "Post & Watch",
    desc2: "Engagement Soar!",
    image: "/eurekaImage/product4.png"
  },
]

export const swiperVideoList = [
  { id: 0, url: "/waywardImage/video_1.mp4", thumb: "/waywardImage/thumb_v1.jpg" },
  { id: 1, url: "/waywardImage/video_2.mp4", thumb: "/waywardImage/thumb_v2.jpg" },
  { id: 2, url: "/waywardImage/video_3.mp4", thumb: "/waywardImage/thumb_v3.jpg" },
  { id: 3, url: "/waywardImage/video_1.mp4", thumb: "/waywardImage/thumb_v1.jpg" },
  { id: 4, url: "/waywardImage/video_2.mp4", thumb: "/waywardImage/thumb_v2.jpg" },
  { id: 5, url: "/waywardImage/video_3.mp4", thumb: "/waywardImage/thumb_v3.jpg" },
]

export const slideshowsList = [
  {id: "1", url: "/eurekaImage/dynamic1.jpg"},
  {id: "2", url: "/eurekaImage/dynamic2.jpg"},
  {id: "3", url: "/eurekaImage/dynamic3.jpg"},
  {id: "4", url: "/eurekaImage/dynamic4.jpg"},
  {id: "5", url: "/eurekaImage/dynamic5.jpg"},
  {id: "6", url: "/eurekaImage/dynamic6.jpg"},
]

export const testimonials = [
  {
    name: "Jessica Barnett",
    url: "/eurekaImage/avatar1.jpg",
    brand: "Slumberpod",
    desc: "Wayward's videos completely blew us away. We had spent months and hundreds of thousands on agency-produced videos, only to see low engagement and miss the mark on TikTok's fast-moving trends. Wayward created 100 higher-quality videos in a single day at a fraction of the cost. We're huge fans, to say the least!"
  }, {
    name: "Melissa Gad",
    url: "/eurekaImage/avatar2.jpg",
    brand: "Colugo",
    desc: "Our branded search on Amazon 10x'd in a week using Wayward. Their platform allowed us to scale our TikTok efforts without having to hire a full-time employee."
  }, {
    name: "Tyler Foster",
    url: "/eurekaImage/avatar3.jpg",
    brand: "Eversprout",
    desc: "Most of our business growth relies on the success of new product launches, and Wayward's technology enabled us to test these products on TikTok before they even went live on Amazon. This gave us invaluable insights and a head start, letting us refine our strategy and build demand ahead of time. Wayward's approach has transformed how we prepare for launches, making the process faster, smarter, and more effective than ever."
  }, {
    name: "Head of Brands",
    url: "/eurekaImage/avatar4.jpg",
    brand: "Large Aggregator",
    desc: "Wayward's technology made it incredibly easy for us to test content rapidly on our TikTok, bypassing the need for costly agencies or complex production setups. We were thrilled to see that the AI-generated videos delivered engagement on par with our user-generated content - and the best part? It came at a fraction of the cost and is infinitely scalable."
  },
];

export const marketings = [
  {
    name: "Unleash Unprecedented Scale",
    desc: "Produce more in less time, without sacrificing quality.",
    url: "/eurekaImage/marketing1.png",
    width: "0.5rem", top: "0.5rem"
  }, {
    name: "Slash Production Costs",
    desc: "Eliminate the need for expensive agencies or freelance videographers.",
    url: "/eurekaImage/marketing2.png",
    width: "0.72rem", top: "0.6rem"
  }, {
    name: "Boost Engagement & Conversions",
    desc: "Captivate your audience with compelling visuals and persuasive storytelling.",
    url: "/eurekaImage/marketing3.png",
    width: "0.68rem", top: "0.5rem"
  }, {
    name: "Gain a Competitive Edge",
    desc: "Outpace your rivals by consistently delivering fresh, engaging content that resonates with your target market.",
    url: "/eurekaImage/marketing4.png",
    width: "0.72rem", top: "0.5rem"
  },
]

export const questionsArr = [
  {
    id: "1",
    problem: "What types of videos can Eureka create for my brand?",
    desc: "Eureka can generate a variety of video types, including social media ads, organic content, UGC (User-Generated Content) style videos, and educational or promotional series—all designed to drive high engagement."
  }, {
    id: "2",
    problem: "What platforms does Eureka support for video distribution?",
    desc: "Eureka videos are optimized for social media platforms like TikTok, Instagram, Facebook, and YouTube. The platform provides options to resize and format videos for each channel."
  }, {
    id: "3",
    problem: "How does Eureka create videos?",
    desc: "Using AI, Eureka analyzes your brand's product information, trends, and audience preferences to automatically script, create, and edit engaging videos tailored to your goals."
  }, {
    id: "4",
    problem: "Can I customize the videos?",
    desc: "Yes, you can adjust elements like caption styles, b-rolls, BGM, to ensure each video aligns with your brand voice and objectives."
  }, {
    id: "5",
    problem: "What types of results can I expect from using Eureka?",
    desc: "Most brands see increased engagement on organic conent, improved ROI on ad spending, and greater reach through organic growth on social media. Results vary, but the platform is designed to enhance brand visibility and engagement."
  }, {
    id: "6",
    problem: "Can I use my own footage or images in Eureka videos?",
    desc: "Yes, Eureka allows you to upload your own brand assets, such as images and video clips, so the generated video aligns perfectly with your brand's identity."
  }, {
    id: "7",
    problem: "Does Eureka support different languages?",
    desc: "At the moment, Eureka can create content in English only. We will supported other languages in the near future."
  }
]

