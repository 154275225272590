import intlMessagesZH from './compiledLocales/zh-hans.json';
import intlMessagesEN from './compiledLocales/en.json';
import { MessageFormatElement } from 'react-intl';

const messagesMap: Record<string, Record<string, MessageFormatElement[]>> = {
  'zh-hans': intlMessagesZH,
  en: intlMessagesEN,
};

type i18nConfig = {
  defaultLocale: string;
  availableLocales: string[];
};
class LocaleService {
  private readonly defaultLocale: string;
  private readonly availableLocales: string[];
  constructor(config: i18nConfig) {
    this.defaultLocale = config.defaultLocale;
    this.availableLocales = config.availableLocales;
  }
  getAvailableLocales() {
    return this.availableLocales;
  }
  getDefaultLocale() {
    return this.defaultLocale;
  }
  getMessages(lang: string) {
    if (this.availableLocales.includes(lang)) {
      return messagesMap[lang];
    }
    return messagesMap[this.defaultLocale];
  }
}

export default new LocaleService({
  defaultLocale: 'en',
  availableLocales: ['en', 'zh-hans'],
});
