import {
  Box, CardMedia, IconButton, Stack, Typography,
  SxProps, Theme
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FilterIcon from '@mui/icons-material/Filter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import {
  AvatarIcon, CreateSquareIcon, PageMoresIcon, PlayPageLogoIcon,
  ProductsFolderIcon, VideoFolderIcon
} from "../../assets/imgs/icons";
import { useGetUser } from "../../hooks/userHook";

export default function PageLeftNavgation({
  upgradeOpen
}:{
  upgradeOpen: (value: boolean) => void;
}){
  const navigate = useNavigate();
  const userInfo = useGetUser();
  const artchRef = useRef<HTMLDivElement>(null);
  const checked = window.location.pathname;
  const checkRouteList = [
    "/", "/videos", "/avatars", "/product-folder", "/slideshow",
    "/account-user", "/promotional"
  ];
  if( !checkRouteList.includes(checked) ){
    window.location.replace("/");
  }
  const [permission, setPermission] = useState<string[]>([]);

  const routerReplace = (url: string)=>{
    navigate(url, { replace: true });
  };
  const createSquare = ()=>{
    if(!userInfo?.sufficient_quota){ upgradeOpen(true); return; }
    navigate("/create-video");
  };

  useEffect(()=>{
    let locals = localStorage.getItem("private:powers");
    if(locals){
      let powers = locals.split(",");
      setPermission(powers);
    }
  },[]);

  const HandleBox = useCallback(({
    icon,
    text,
    routed,
    onClick,
  }:{
    icon: React.ReactNode,
    text: string,
    routed: string,
    onClick?: ()=>void
  })=>{
    
    return (
      <Stack width="100%" direction="row" p="0.15rem 0.16rem" gap="0.12rem"
        sx={{
          cursor: "pointer", borderRadius: "0.12rem",
          color: checked === routed ? "#fff" : "#858b95",
          bgcolor: checked === routed ? "#FFFFFF1A" : "transparent",
          "&:hover": { bgcolor: "#FFFFFF1A" }
        }}
        onClick={ ()=> { onClick && onClick() }  }>
        {icon}
        <Typography sx={{ color: "#FFFFFF", fontSize: "0.16rem" }}>
          {text}
        </Typography>
      </Stack>
    )
  },[checked]);

  const styleSelect: {
    [key: string]: SxProps<Theme>
  } = useMemo(()=>{
    let obj: {[key: string]: SxProps<Theme>} = {
      total: {
        width: "2.8rem", p: "0.24rem 0.16rem",
        borderRadius: "0.12rem", flexShrink: 0, position: "relative", bgcolor: "#FFFFFF1A"
      },
      total_txt: {
        fontSize: "0.2rem", lineHeight: "0.28rem",
        color: "#FFFFFF", cursor: "pointer", "&:hover": { color: "#6D5BD7" }
      }
    }
    return obj
  },[]);

  return (
    <Stack sx={styleSelect.total}>
      <Stack width="100%" direction="row" gap="0.12rem" alignItems="center">
        <PlayPageLogoIcon sx={{ fontSize:"0.24rem" }} />
        <Typography sx={styleSelect.total_txt} onClick={ ()=> navigate("/", {replace: true}) }>
          Eureka
        </Typography>
        <IconButton sx={{ p: "0.02rem", ml: "auto" }} onClick={ ()=> navigate("/", {replace: true}) }>
          <PageMoresIcon sx={{ fontSize:"0.24rem" }} />
        </IconButton>
      </Stack>
      <Stack mt="0.24rem" flexGrow={1}>
        {/* position="fixed" */}
        {/* backdrop-filter: blur(60px) */}
        {/* background: #0E173199; */}
        <HandleBox icon={<CreateSquareIcon sx={{ fontSize:"0.2rem" }} />}
          text="Create" routed="/create-video" onClick={createSquare} />
        <Box sx={{ width: "100%", height: "0.01rem", bgcolor: "#ECEFF026", m: "0.12rem 0" }}></Box>
        <HandleBox icon={<VideoFolderIcon sx={{ fontSize:"0.2rem" }} />}
          text="Videos" routed="/videos" onClick={ ()=> routerReplace("/videos") } />
        { permission.includes("slideshow") && (
          <HandleBox icon={<FilterIcon sx={{ fontSize:"0.2rem" }} />}
            text="Slideshow" routed="/slideshow" onClick={ ()=> routerReplace("/slideshow") } />
        ) }
        <HandleBox icon={<AvatarIcon sx={{ fontSize:"0.2rem" }} />}
          text="Avatars" routed="/avatars" onClick={ ()=> routerReplace("/avatars") } />
        <HandleBox icon={<ProductsFolderIcon sx={{ fontSize:"0.2rem" }} />}
          text="Products" routed="/product-folder" onClick={ ()=> routerReplace("/product-folder") } />
        { permission.includes("promotional") && (
          <HandleBox icon={<ShoppingCartIcon sx={{ fontSize:"0.2rem" }} />}
            text="Promotional" routed="/promotional" onClick={ ()=> routerReplace("/promotional") } />
        ) }
          
        <Box flexGrow={1}></Box>
        <Box ref={artchRef} sx={{ position: "relative" }}>
          <HandleBox icon={<AccountCircleOutlinedIcon sx={{ fontSize:"0.24rem", color: "#FFFFFF" }} />}
            text="Account" routed="/account-user" onClick={ ()=> routerReplace("/account-user") } />
        </Box>
      </Stack>
      
    </Stack>
  )
}