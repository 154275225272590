import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Box, CardMedia, Stack, styled } from "@mui/material";
import { useBreakpointSm } from "../../utils/useStyleHooks";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';

const DivBox = styled("div")({
  cursor: "pointer",
});

export default function SwiperVideo(){
  const isSM = useBreakpointSm();
  const mediaList = useMemo(() => [
    { id: 0, url: "/waywardImage/video_1.mp4", thumb: "/waywardImage/thumb_v1.jpg" },
    { id: 1, url: "/waywardImage/video_2.mp4", thumb: "/waywardImage/thumb_v2.jpg" },
    { id: 2, url: "/waywardImage/video_3.mp4", thumb: "/waywardImage/thumb_v3.jpg" },
    { id: 3, url: "/waywardImage/video_1.mp4", thumb: "/waywardImage/thumb_v1.jpg" },
    { id: 4, url: "/waywardImage/video_2.mp4", thumb: "/waywardImage/thumb_v2.jpg" },
    { id: 5, url: "/waywardImage/video_3.mp4", thumb: "/waywardImage/thumb_v3.jpg" },
  ], []);
  const swiperRef = useRef<any | null>(null);
  const [hiddenPlay, setHiddenPlay] = useState(false);

  const createSwiper = () => {
    var swiper = new window.Swiper(".swiper", {
      effect: "coverflow",
      loop: true,
      slidesPerView: 3,
      centeredSlides: true,
      stretch: -1,
      sliceShadows: false,
      coverflowEffect:{
        rotate: 40
      },
      
      on: {
        click: function(){
          const _t = this as any;
          if(_t.clickedIndex === _t.activeIndex){
            let media = _t.slides[_t.activeIndex].querySelector("video") as HTMLVideoElement;
            if(media.paused){
              media.play();
            }else{
              media.pause();
            }
            return;
          }
          let medialist = _t.slides.map((slide: any) => slide.querySelector("video"))
          medialist.forEach( (media: HTMLVideoElement) => !media.paused && media.pause() );
          if(_t.clickedIndex > _t.activeIndex){
            _t.slideNext()
          }else{
            _t.slidePrev()
          }
        },
        touchEnd: function(){
          const _t = this as any;
          if(_t.clickedIndex === _t.activeIndex){
            let media = _t.slides[_t.activeIndex].querySelector("video") as HTMLVideoElement;
            if(media.paused){
              media.play();
            }else{
              media.pause();
            }
          }
          let medialist = _t.slides.map((slide: any) => slide.querySelector("video"))
          medialist.forEach( (media: HTMLVideoElement) => !media.paused && media.pause() );

        }
      }
    });
    swiperRef.current = swiper;
  };

  const videoPlayChange = (id: number) => {
    setHiddenPlay(true);

  }
  const videoPauseChange = (id: number) => {
    setHiddenPlay(false);
  }
  const checkedPlay = ()=>{
    let swiper = swiperRef.current;
    if(!swiper) return;
    let index = swiper.activeIndex;
    let media = swiper.slides[index].querySelector("video") as HTMLVideoElement;
    if(media.paused){
      media.play();
    }else{
      media.pause();
    }
  }

  useEffect(() => {
    createSwiper();
    return () => {
      swiperRef.current?.destroy();
    }
  }, [isSM]);

  

  const RingureGreander = useCallback(({
    direction
  }:{
    direction: "left" | "right"
  }) => {
    let rotate = direction === "left" ? "90deg" : "270deg";
    return (
      <Box position="absolute" top="0" height="100%" width="1rem"
        sx={{
          zIndex: "3",
          pointerEvents: "none",
          left: direction === "left" ? "0.8rem" : "auto",
          right: direction === "right" ? "0.8rem" : "auto",
          background: "linear-gradient(" + rotate + ", #F3F2FDCA 20%, rgba(0,0,0,0) 100%)",
        }}>
        
      </Box>
    )
  }, []);

  return (
    <Box sx={{
      overflow: "hidden", width: isSM ? "6.1rem" : "10.16rem",
      flexShrink: 0, height: isSM ? "3.6rem" : "auto",
      ml: isSM ? "-1.05rem" : "0",
      mt: isSM ? "0.6rem" : "0", position: "relative",
    }}>
      {isSM && (
        <>
          <RingureGreander direction="left" />
          <RingureGreander direction="right" />
        </>
      )}
      { hiddenPlay ? (
        <Box sx={{
          p: "0.12rem", bgcolor: "#ffffff3d", borderRadius: "50%",
          position: "absolute", top: "50%", left: "50%",
          transform: "translate(-50%, -50%)", opacity: 0,
          zIndex: 8, cursor: "pointer",
          "&:hover":{ opacity: 1 }
        }} onClick={checkedPlay}>
          <Stack sx={{
            width: "0.8rem", height: "0.8rem",
            alignItems: "center", justifyContent: "center", 
          }}>
            <PauseRoundedIcon sx={{ color: "#ffffff", fontSize: "0.4rem" }} />
          </Stack>
        </Box>
      ) : (
        <Box sx={{
          p: isSM ? "0.06rem" : "0.12rem", bgcolor: "#ffffff3d", borderRadius: "50%",
          position: "absolute", top: "50%", left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 8, cursor: "pointer",
          "&:hover .MuiStack-root": {
            boxShadow: "0 0 0.4rem 0.1rem #ffffff",
          }
        }} onClick={checkedPlay}>
          <Box sx={{ p: isSM ? "0.06rem" : "0.12rem", bgcolor: "#ffffff3d", borderRadius: "50%" }}>
            <Stack sx={{
              alignItems: "center", justifyContent: "center", borderRadius: "50%",
              bgcolor: "#08153A",
              width: isSM ? "0.4rem" : "0.78rem",
              height: isSM ? "0.4rem" : "0.78rem",
              transition: "box-shadow 0.2s",
            }}>
              <PlayArrowRoundedIcon sx={{
                color: "#ffffff", fontSize: isSM ? "0.2rem" : "0.4rem"
              }} />
            </Stack>
          </Box>
        </Box>
      ) }
      
      <DivBox className="swiper">
        <DivBox className="swiper-wrapper">
          {mediaList.map((media) => (
            <DivBox key={media.id} className="swiper-slide">
              <CardMedia component="video" loop src={media.url} playsInline
                preload="auto"
                onPlay={()=> videoPlayChange(media.id) }
                onPause={()=> videoPauseChange(media.id) }
                sx={{
                  position: "absolute", top: 0, left: 0,
                  width: "100%", height: "100%", zIndex: 1,
                }} />
              <CardMedia component="img" src={media.thumb}
                sx={{ maxHeight: isSM ? "3.6rem" : "6.02rem", width: "auto" }} />
            </DivBox>
          ))}
        </DivBox>
      </DivBox>
    </Box>
    
  )
}