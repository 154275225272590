import { Box, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { EGradientTxt } from "./componted";
import { questionsArr } from "./aTemplate";
import { useBreakpointSm } from "../../utils/useStyleHooks";

export default function EurekaQuestions(){
  const isSM = useBreakpointSm();
  const [checked, setChecked] = useState("2");
  const questionsList = useMemo(()=> questionsArr,[]);

  return (
    <Stack direction={isSM ? "column" : "row"} sx={{
      maxWidth: "19.2rem", m: isSM ? "0.6rem auto 0" : "2.1rem auto 0",
    }}>
      <Box sx={{
        width: isSM ? "100%" : "9rem", p: isSM ? "0 0.2rem 0" : "0 1rem 0 1.5rem"
      }}>
        <Typography sx={{
          fontFamily: "ClashDisplay",
          fontSize: isSM ? "0.32rem" : "0.76rem",
          lineHeight: isSM ? "0.32rem" : "0.76rem", 
        }} component="h2">
          <span>Frequently</span>
          {!isSM ? <br /> : <span> </span>}
          <span>Asked </span><EGradientTxt>Questions</EGradientTxt>
        </Typography>
        {/* {isSM ? (
          <Typography sx={{
            fontFamily: "Satoshi", mt: isSM ? "0.24rem" : "0.3rem",
            fontSize: isSM ? "0.16rem" : "0.2rem",
            lineHeight: isSM ? "0.24rem" : "0.3rem",
          }}>
            Welcome to our FAQ! Here, you'll find answers to the most commonly asked questions about our product/service. We've taken the time to provide you with clear and concise answers to help you make informed decisions.
          </Typography>
        ) : (
          <Typography sx={{
            fontFamily: "Satoshi", fontSize: "0.2rem",
            lineHeight: "0.3rem", mt: "0.3rem",
          }}>
            <span>Welcome to our FAQ! Here, you'll find answers to the most</span><br />
            <span>commonly asked questions about our product/service. We've taken</span><br />
            <span>the time to provide you with clear and concise answers to help you</span><br />
            <span>make informed decisions.</span>
          </Typography>
        )} */}
      </Box>
      <Box p={isSM ? "0.3rem 0.2rem 0" : "0"}>
        {questionsList.map((item,index)=>(
          <Box key={index} sx={{
            width: isSM ? "100%" : "9rem", cursor: "pointer",
            borderRadius: "0.18rem",
            boxShadow: checked === item.id ? "-0.08rem 0.1rem 0.75rem 0 #7422D85C" : "none",
            backgroundImage: checked === item.id ? "linear-gradient(282.89deg, #5034E9 -8.77%, #9B8BF9 113.69%)" : "url(/eurekaImage/questions_bg.png)",
            backgroundSize: "100% 100%", mb: "0.24rem",
          }} onClick={ ()=> setChecked(item.id) }>
            <Typography sx={{
              fontFamily: "Satoshi",
              fontSize: isSM ? "0.16rem" : "0.2rem",
              lineHeight: isSM ? "0.22rem" : "0.32rem",
              fontWeight: "400",
              p: isSM ? "0.16rem 0.18rem" : "0.24rem 0.3rem",
            }}>
              {item.problem}
            </Typography>
            { checked === item.id && (
              <Box sx={{ width: "100%", height: "0.01rem", bgcolor: "#FFFFFF99" }}></Box>
            )}
            <Typography sx={{
              fontFamily: "Satoshi",
              fontSize: isSM ? "0.16rem" : "0.2rem",
              lineHeight: isSM ? "0.2rem" : "0.32rem",
              fontWeight: "400",
              p: isSM ? "0.16rem 0.18rem" : "0.24rem 0.3rem",
              display: checked === item.id ? "block" : "none",
            }}>
              {item.desc}
            </Typography>

          </Box>
        ))}
      </Box>
    </Stack>
  )
}