import {
  useState, useCallback, useImperativeHandle, forwardRef, createRef, useRef
} from "react";
import {
  Box, Stack, Typography, CardMedia, IconButton, Fade, Collapse
} from "@mui/material";
import {
  createStyles, makeStyles
} from "@mui/styles";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import {
  CrossIcon
} from "../../assets/imgs/icons";
import { useBreakpointSm } from "../../utils/useStyleHooks";

const SHOWMESSAGEDURATION = 6000;
type SeverityType = 'success' | 'error';
type ReactActions<T> = React.Dispatch<React.SetStateAction<T>>;
type MsgTypes = {
  tip?: string;
  message: string | string[];
  severity: SeverityType;
}

export const MessageRef: React.RefObject<{
  show: fnVoidToVoid;
  hide: fnVoidToVoid;
  message: ReactActions<MsgTypes>;
  severity: ReactActions<SeverityType>;
}> = createRef();

const TipTextureMessage = forwardRef((_: any, ref: any)=>{
  const [open, setOpen] = useState(false);
  const isSM = useBreakpointSm();
  const styles = useStyles();
  const nodeRef = useRef(null);
  const [message, setMessage] = useState<MsgTypes>({
    tip: "Tip",
    message: "",
    severity: "success"
  });

  const bgImage = {
    success: <CheckCircleRoundedIcon sx={{ fontSize: "0.2rem", color: "#00CC00" }} />,
    error: <ErrorRoundedIcon sx={{ fontSize: "0.2rem", color: "#CC0000" }} />
  }
  
  const show = useCallback(() => setOpen(true), []);
  const hide = useCallback(() => setOpen(false), []);
  useImperativeHandle(ref, () => ({ show, hide, message: setMessage }));

  const typogStyle = {
    color: "#FFF", fontSize: "0.14rem", fontWeight: "400", lineHeight: "0.2rem"
  };
  const iconSx = {width: "0.12rem", height: "0.12rem" };
  
  return (
    <Fade in={open} mountOnEnter unmountOnExit>
      <Stack direction="row" alignItems="center" className={`${styles.rootTips}`} ref={nodeRef}
        bgcolor="rgba(0,0,0,0.9)" p="0.12rem" width={ isSM ? "100%" : "auto" }>
        { bgImage[message.severity] }
        <Box ml="0.1rem" position="relative" flexGrow={1}>
          {
            message.message instanceof Array ? (
              message.message.map((msg, index) => (
                <Typography key={index} component="p" sx={typogStyle}>{msg}</Typography>
              ))
            ) : (
              <Typography component="p" sx={typogStyle}>{
                message.message.slice(0, 150)
              }</Typography>
            )
          }
        </Box>
        <IconButton onClick={hide} sx={{ ml: "0.1rem" }}>
          <CrossIcon stroke="#BBB" sx={ iconSx } />
        </IconButton>
      </Stack>
    </Fade>
  );
})

export const MessageTip = () => <TipTextureMessage ref={MessageRef} />;

let timer: NodeJS.Timeout;
function Message(
  message: MsgTypes["message"],
  title?: string,
  severity: SeverityType = 'success',
  duration = SHOWMESSAGEDURATION
) {
  try {
    MessageRef.current?.show();
    MessageRef.current?.message({
      tip: title,
      message,
      severity
    });
    clearTimeout(timer);
    timer = setTimeout(() => {
      MessageRef.current?.hide();
    }, duration);
  } catch {}
}

export const showMessage = {
  success: function(message: MsgTypes["message"], title?: string) {
    Message(message, title, "success");
  },
  error: function(message: MsgTypes["message"], title?: string) {
    Message(message, title, "error");
  } 
};

const useStyles = makeStyles(() =>
  createStyles({
    rootTips: {
      position:"fixed",
      top: "1rem",
      zIndex: 987654,
      left: "50%",
      transform: "translateX(-50%)",
      minWidth: "1.6rem",
      maxWidth: "80%",
      borderRadius: "0.12rem",
      overflow: "hidden"
    }
  })
);
