import {
  Box,
  CircularProgress,
  Stack
} from "@mui/material";
import {
  lazy,
  Suspense,
  useEffect,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom"
import PageLeftNavgation from "./tools/pageLeftNavgation";
import PageNavgationPhone from "./tools/pageNavgationPhone";
import { useBreakpointSm } from "../utils/useStyleHooks";
import { useGetUserAPI } from "../redux/projectApi";
import { setUserInfo } from "../redux/slice/AuthSlice";
const Projects = lazy(() => import("./pageRouter/projects"));
const Avatars = lazy(() => import("./pageRouter/avatars"));
const Products = lazy(() => import("./pageRouter/products"));
const PrimaryPage = lazy(() => import("./pageRouter/primaryPage"));
const Slideshow = lazy(() => import("./pageRouter/slideshow"));
const AccountUser = lazy(() => import("./account/accountUser"));
const PlanUpgradeDialog = lazy(() => import("./meal/PlanUpgrade"));
const Promotional = lazy(() => import("./pageRouter/promotional"));

export default function HomePage(){
  const isSM = useBreakpointSm();
  const [tipOpen, setTipOpen] = useState(false);
  const getUser = useGetUserAPI();
  const dispatch = useDispatch();

  useEffect(()=>{
    getUser({
      onSuccess(res) {
        if(res.data) dispatch( setUserInfo(res.data) );
      },
    })
  }, []);

  return (
    <Stack direction={ isSM ? "column" : "row" }
      width="100%" height="100%" p={ isSM ? "0" : "0.16rem" } gap="0.2rem">
      <Suspense fallback={<></>}>
        <PlanUpgradeDialog open={tipOpen} setOpen={setTipOpen} />
      </Suspense>
      { isSM ?
        <PageNavgationPhone upgradeOpen={setTipOpen} /> :
        <PageLeftNavgation upgradeOpen={setTipOpen} />
      }

      <Box flexGrow="1" height="100%" overflow="hidden">
        <Routes>
          <Route path="/avatars" element={
            <Suspense fallback={ <CircularProgress /> }>
              <Avatars />
            </Suspense>
          } />
          <Route path="/product-folder" element={
            <Suspense fallback={ <CircularProgress /> }>
              <Products upgradeOpen={setTipOpen} />
            </Suspense>
          } />
          <Route path="/videos" element={
            <Suspense fallback={ <CircularProgress /> }>
              <Projects upgradeOpen={setTipOpen} />
            </Suspense>
          } />
          <Route path="/slideshow" element={
            <Suspense fallback={ <CircularProgress /> }>
              <Slideshow />
            </Suspense>
          } />
          <Route path="/promotional" element={
            <Suspense fallback={ <CircularProgress /> }>
              <Promotional upgradeOpen={setTipOpen} />
            </Suspense>
          } />
          <Route path="/account-user" element={
            <Suspense fallback={ <CircularProgress /> }>
              <AccountUser />
            </Suspense>
          } />
          
          <Route path="/" element={
            <Suspense fallback={ <CircularProgress /> }>
              <PrimaryPage />
            </Suspense>
          } />
        </Routes>
      </Box>
    </Stack>
  );
}