import {
  Box, CardMedia, IconButton, Link, Stack, Typography,
  styled
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { GenericEmbed, PopupButton } from "@typeform/embed-react";

import {
  PageStepAIcon,
  PageStepBIcon,
  PageStepCIcon,
  PageStepDIcon,
} from "../assets/imgs/icons";
import { ButtonText } from "../components/muiCustom/Button";
import StepTags from "./homepageTool/stepTags";
import SwiperVideo from "./homepageTool/swiperVideo";

import { GradientTxt } from "./homepageTool/componted";
import { useBreakpointSm } from "../utils/useStyleHooks";
import { createRem } from "../utils/rem";

const StepCustomer = lazy(() => import("./homepageTool/stepCustomer"));
const WaywardCreativity = lazy(() => import("./homepageTool/waywardCreativity"));
const WaywardChaos = lazy(() => import("./homepageTool/waywardChaos"));
const WaywardQuality = lazy(() => import("./homepageTool/waywardQuality"));
const WaywardFooder = lazy(() => import("./homepageTool/waywardFooder"));

const remChange = createRem(true);
remChange();

const InheritTxt = styled("span")({
  fontFamily: "inherit", fontSize: "inherit", lineHeight: "inherit"
});

export default function HomePageWayward(){
  const isSM = useBreakpointSm();
  const high_performings = useMemo(()=>[
    { id: "Step1", name: "Tell Us About Your Product", desc: "Sign up and share your product links, brand assets and anything else that makes your brand awesome!", url: "/waywardImage/page_step1.jpg"},
    { id: "Step2", name: "We Generate Video Ideas", desc: "Our AI, guided by human expertise, analyzes top-performing trends and video styles to create a variety of scripts tailored to your brand.", url: "/waywardImage/page_step2.jpg" },
    { id: "Step3", name: "Get Videos in 24 Hours", desc: "We produce engaging videos using your existing assets, stock footage, AI-generated b-rolls, voiceovers, and music. Use them as-is or request edits.", url: "/waywardImage/page_step3.jpg" },
    { id: "Step4", name: "Watch Your Engagement Soar!", desc: "Our analytics tools track video performance, identifying what resonates with your audience so we can produce more of what works—continuously fueling your social media growth.bb.", url: "/waywardImage/page_step4.jpg" }
  ],[]);
  const performings_icon = useMemo(()=>[
    <PageStepAIcon sx={{ fontSize: "0.49rem" }} />,
    <PageStepBIcon sx={{ fontSize: "0.49rem" }} />,
    <PageStepCIcon sx={{ fontSize: "0.49rem" }} />,
    <PageStepDIcon sx={{ fontSize: "0.49rem" }} />
  ],[]);
  const popupBtnRef = useRef<GenericEmbed>();

  const count = useRef(0);
  const [openlink, setOpenlink] = useState(false);

  const RingureGreander = useCallback(({
    direction
  }:{
    direction: "left" | "right"
  }) => {
    let rotate = direction === "left" ? "90deg" : "270deg";
    return (
      <Box position="absolute" top="0" height="100%" width="21.21%"
        zIndex="1" sx={{
          pointerEvents: "none",
          left: direction === "left" ? "0" : "auto",
          right: direction === "right" ? "0" : "auto",
          background: "linear-gradient(" + rotate + ", #F3F2FD 21.51%, rgba(0,0,0,0) 80.4%)",
        }}>
      </Box>
    )
  }, []);

  const openFormEmbed = () => {
    popupBtnRef.current?.open();
  }

  const foolineList = useRef<{dom: HTMLElement, top: number, height: number}[]>([]);
  const maxHeight = useRef(0);

  const scrollChange = (e: React.UIEvent<HTMLDivElement>) => {
    if(count.current < 10){ count.current += 1; return; };
    count.current = 0;
    let mx_h = maxHeight.current;
    let list = foolineList.current;

    let top = e.currentTarget.scrollTop;
    let mgtop = list[0].top - mx_h;
    if(top < mgtop) return;
    let end = list[list.length-1];
    if(top > end.top) return;

    let wrid_top = top + mx_h;
    let mt_threetwo = mx_h / 3 * 2;
    for(let i=0; i<list.length; i++){
      let item = list[i];
      let com_t = wrid_top - item.top
      if(com_t > 0){
        let opc = 1;
        if(com_t < mt_threetwo) opc = com_t / mt_threetwo;
        item.dom.style.opacity = opc+"";
      }else{
        break;
      }
    }
  }

  let getfoolline = () => {
    let fooline = document.querySelectorAll<HTMLElement>("[id^='eureka-pagemodal-']");
    foolineList.current = [];
    fooline.forEach((item) => {
      let {top, height} = item.getBoundingClientRect();
      item.style.opacity = "0";
      foolineList.current.push({ dom: item, top, height });
    });
    maxHeight.current = window.innerHeight;
  }

  useEffect(() => {
    getfoolline();
    window.onload = ()=>{ getfoolline(); };
  },[]);

  const componentSx = useMemo(()=>({
    "bgStyle1": isSM ? {
      width:"4rem", height:"4rem", right:"-1.5rem", top:"11.32rem", filter: "blur(1.6rem)"
    }:{
      width:"3.1rem", height:"3.7rem", right:"-1.5rem", top:"11.9rem", filter: "blur(1rem)"
    },
    "bgStyle2": isSM ? {
      width:"2.72rem", height:"2.94rem", left:"0", top:"28rem", filter: "blur(1rem)"
    }:{
      width:"3.1rem", height:"3.7rem", left:"0", top:"28rem", filter: "blur(1rem)"
    },
    "bgStyle3": isSM ? {
      width:"2.9rem", height:"3.16rem", left:"-1.4rem", top:"42.84rem", filter: "blur(1.8rem)"
    }:{
      width:"3.1rem", height:"3.7rem", left:"0", top:"41.2rem", filter: "blur(1.8rem)"
    },
    "bgStyle4": isSM ? {
      display: "none"
    }:{
      width:"4.7rem", height:"4.7rem", right:"0", top:"38rem", filter: "blur(2.8rem)"
    },
    "bgStyle5": isSM ? {
      width:"3.8rem", height:"3.8rem", left:"-2rem", top:"53rem", filter: "blur(2.4rem)"
    }:{
      width:"3.7rem", height:"3.7rem", left:"-1.86rem", top:"57rem", filter: "blur(2rem)"
    },
    "bgStyle6": isSM ? {
      width:"3.1rem", height:"3.1rem", right:"0", top:"61rem", filter: "blur(1.8rem)"
    }:{
      width:"3.1rem", height:"3.7rem", right:"0", top:"61.4rem", filter: "blur(1.8rem)"
    },
    "bgStyle7": isSM ? {
      width:"3rem", height:"3rem", left:"0.65rem", top:"65rem", filter: "blur(1.6rem)"
    }:{
      width:"4.7rem", height:"4.7rem", left:"7.25rem", top:"64.2rem", filter: "blur(2.8rem)"
    },
  }),[isSM]);

  return (
    <Box id="eureka-parentall" sx={{
      bgcolor: "#FFFFFF", overflowY: "auto", overflowX: "hidden",
      width: "100%", height: "100%", position:"relative",
    }} onScroll={scrollChange}>
    <PopupButton id="UEFJkLW8" style={{ display: "none" }} embedRef={popupBtnRef}>
      open click
    </PopupButton>
      <Box sx={{
        ...componentSx.bgStyle1,
        position:"absolute", zIndex:"0", bgcolor: "#67d2d086", borderRadius: "50%",
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle2,
        position:"absolute", zIndex:"0", bgcolor: "#67d2d086", borderRadius: "50%", transform: "translateX(-50%)"
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle3,
        position:"absolute", zIndex:"0", bgcolor: "#67d2d0", borderRadius: "50%", transform: "translateX(-50%)"
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle4,
        position:"absolute", zIndex:"0", bgcolor: "#5D49D2", borderRadius: "50%", transform: "translateX(50%)"
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle5,
        position:"absolute", zIndex:"0", bgcolor: "#5D49D286", borderRadius: "50%",
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle6,
        position:"absolute", zIndex:"0", bgcolor: "#67d2d0", borderRadius: "50%", transform: "translateX(50%)"
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle7,
        position:"absolute", zIndex:"0", bgcolor: "#5D49D2", borderRadius: "50%",
      }}></Box>

      <Box sx={{
        maxWidth: "19.2rem", width: "100%", p: isSM ? "0.16rem 0.18rem" : "0.22rem",
        m: "0 auto",
      }}>
        <Box sx={{
          backgroundImage: isSM ? "url(/waywardImage/bgWayward2.jpg)" : "url(/background/wayward1.jpg)",
          backgroundSize: "100% 100%",
          overflow: "hidden", borderRadius: "0.3rem", position: "relative"
        }}>
          <Stack direction="row" sx={{
            p: isSM ? "0.24rem" : "0.26rem 1rem",
            justifyContent: "space-between",
            position: "relative", zIndex: "2"
          }}>
            <Box width="3rem" pt={isSM ? "0" : "0.1rem"}>
              <CardMedia component="img" src="/logoPicture/wayward.png"
                sx={{ width: isSM ? "1.36rem" : "1.96rem" }} />
            </Box>
            { isSM ? (
              <Box sx={{ position: "relative" }}>
                <CardMedia component="img" src="/waywardImage/phoneMore.png"
                  sx={{ width: "0.32rem" }}
                  onClick={()=> setOpenlink(!openlink) } />
                <Stack sx={{
                  position: "absolute", top: "0.36rem", right: "0rem",
                  bgcolor: "#DCDCDC", borderRadius: "0.1rem",
                  boxShadow: "0 0 0.2rem #DCDCDC", p: "0.1rem",
                  width: "1rem", textAlign: "center",
                  display: openlink ? "flex" : "none"
                }}>
                  <Link sx={{
                    fontSize: "0.16rem", color: "#0C152D", fontFamily: "Satoshi"
                  }} href="/account/login">
                    Sign In
                  </Link>
                </Stack>
              </Box>
            ) : (
              <Stack width="3rem" direction="row" justifyContent="space-between"
                alignItems="center">
                <Link sx={{ fontSize: "0.18rem", color: "#0C152D", fontFamily: "Satoshi" }}
                  href="/account/login">
                  Sign In
                </Link>
                <Box sx={{width: "0.01rem", height: "100%", bgcolor: "#0C152D"}}></Box>
                <Stack direction="row" sx={{
                  alignItems: "center", p: "0.25rem 0.28rem", cursor: "pointer",
                  backgroundImage: "url(/waywardImage/pageStarted.png)",
                  backgroundSize: "100% 100%",
                }} onClick={openFormEmbed}>
                  <Typography sx={{
                    fontFamily: "Satoshi", fontSize: "0.18rem",
                    color: "#0C152D", lineHeight: "0.18rem", mr: "0.1rem"
                  }}>
                    Get Started
                  </Typography>
                  <ArrowForwardRoundedIcon sx={{ fontSize: "0.18rem", color: "#0C152D" }} />
                </Stack>
              </Stack>
            ) }
            
          </Stack>
          {!isSM && (
            <>
              <RingureGreander direction="left" />
              <RingureGreander direction="right" />
            </>
          )}

          <Stack direction={isSM ? "column" : "row"}
            p={isSM ? "0.5rem 0" : "1rem 0 1.7rem 1.2rem"}>
            <Stack
              sx={{
                position: "relative", flexGrow: 1,
                zIndex: "3", justifyContent: "center",
                maxWidth: "100%",
              }}>
              { isSM ? (
                <Typography sx={{
                  fontFamily: "ClashDisplay", fontSize: "0.44rem",
                  color: "#0C152D", lineHeight: "0.44rem",
                  letterSpacing: "-0.1px", textAlign: "center"
                }}>
                  <span>Unlock Explosive Growth with</span><br />
                  <GradientTxt >AI-Powered </GradientTxt><br />
                  <span>Video.</span>
                </Typography>
              ) : (
                <Typography sx={{
                  fontFamily: "ClashDisplay", fontSize: "0.76rem",
                  color: "#0C152D", lineHeight: "0.76rem",
                  letterSpacing: "-0.1px"
                }}>
                  <span>Unlock Explosive Growth with</span><br />
                  <GradientTxt >AI-Powered </GradientTxt>
                  <span>Video.</span>
                </Typography>
              )}
              <Stack sx={{
                fontSize: isSM ? "0.16rem" : "0.22rem",
                lineHeight: isSM ? "0.22rem" : "0.3rem",
                color: "#0C152D",
                mt: "0.12rem",
                textAlign: isSM ? "center" : "left",
                p: isSM ? "0 0.32rem" : "0"
              }}>
                <InheritTxt>
                  Faster, cheaper, and more scalable than traditional agencies. Our AI-driven high-quality videos
                  are the key to skyrocketing your brand's organic social growth.
                </InheritTxt>
              </Stack>
              <Stack direction="row" mt="0.42rem"
                justifyContent={isSM ? "center" : "flex-start"}>
                <Stack direction="row" sx={{
                  cursor: "pointer",
                  alignItems: "center", p: isSM ? "0.18rem 0.22rem" : "0.25rem 0.28rem",
                  backgroundImage: "url(/waywardImage/pageStarted2.png)",
                  backgroundSize: "100% 100%",
                  filter: "drop-shadow(-0.05rem 0.1rem 0.6rem #1587E8)"
                }} onClick={openFormEmbed}>
                  <Typography sx={{
                    fontFamily: "Satoshi", fontSize: isSM ? "0.14rem" : "0.18rem",
                    lineHeight: isSM ? "0.14rem" : "0.18rem",
                  }}>
                    Get Started
                  </Typography>
                  <ArrowForwardRoundedIcon sx={{ fontSize: "0.18rem", color: "#FFF" }} />
                </Stack>
              </Stack>
            </Stack>
            <SwiperVideo />

          </Stack>
        </Box>
      </Box>
      <Box p="1.2rem 0.2rem 0.2rem" >
        <Typography sx={{
          fontFamily: "ClashDisplay",
          textAlign: "center", color: "#0C152D",
          fontSize: isSM ? "0.32rem" : "0.68rem",
          lineHeight: isSM ? "0.32rem" : "0.68rem",
        }}>
          <span>Engaging, </span>
          <GradientTxt >High-Performing Videos</GradientTxt>
          {!isSM ? <br /> : <span> </span>}
          <span>at a Fraction of the Cost</span>
        </Typography>

        <Stack sx={{
          fontSize: "0.18rem", lineHeight: "0.26rem",
          color: "#464646",
          m: "0.16rem auto 0",
          maxWidth: "6.3rem",
          width: "100%",
        }}>
          <InheritTxt sx={{ textAlign: "center" }}>
            Social media demands constant content. Our agency makes it easy to create
            high-quality, affordable videos.
          </InheritTxt>
        </Stack>

        <Box maxWidth="12.6rem" m={ isSM ? "0.16rem auto 0" : "1.6rem auto 0" }>
          <Stack direction="row" sx={{
            m: "0.44rem auto 0.6rem", alignItems: "center", justifyContent: "center"
          }} gap="0.12rem">
            <Box sx={{ width: "0.12rem", height: "0.12rem", borderRadius: "0.06rem", bgcolor: "#CFC9FF59" }}></Box>
            <Box p="0.13rem" sx={{ borderRadius: "0.28rem", bgcolor: "#CFC9FF59" }}>
              <Stack sx={{
                justifyContent: "center", alignItems: "center",
                borderRadius: "0.3rem", width: "0.3rem", height: "0.3rem",
                background: "linear-gradient(90deg, #5688ce 0, #20aebe 100%)"
              }}>
                <KeyboardArrowDownRoundedIcon sx={{ fontSize: "0.2rem" }} />
              </Stack>
            </Box>
            <Box sx={{ width: "0.12rem", height: "0.12rem", borderRadius: "0.06rem", bgcolor: "#CFC9FF59" }}></Box>
          </Stack>
          <Stack width="100%" gap={ isSM ? "0.66rem" : "1.4rem"} position="relative">
            <Box sx={{
              position: "absolute", top: "0", left: isSM ? "0.14rem" : "50%",
              width: "0.01rem", height: "100%", bgcolor: "#cdcdcd"
            }}></Box>
            { high_performings.map((item, index) => (
              <StepTags key={index} direction={index % 2 === 0 ? "right" : "left"}
                data={item} icon={performings_icon[index]} isSM={isSM} />
            ))}
          </Stack>
        </Box>
      </Box>
      <Suspense fallback={<></>}>
        <StepCustomer />
      </Suspense>
      <Suspense fallback={<></>}>
        <WaywardCreativity openFormEmbed={openFormEmbed} />
      </Suspense>
      <Suspense fallback={<></>}>
        <WaywardChaos />
      </Suspense>
      <Suspense fallback={<></>}>
        <WaywardQuality openFormEmbed={openFormEmbed} />
      </Suspense>
      <Suspense fallback={<></>}>
        <WaywardFooder />
      </Suspense>
    </Box>
  )
}
