import { Box, CardMedia, Rating, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { QuotationMarksIcon } from "../../assets/imgs/icons";
import { testimonials } from "./aTemplate";
import { useBreakpointSm } from "../../utils/useStyleHooks";

export default function EurekaCustomer(){
  const isSM = useBreakpointSm();
  const [checkId, setCheckId] = useState(testimonials[1].name);
  const customerList = useMemo(()=> testimonials,[]);
  const swiperRef = useRef<any | null>(null);

  const customerChange = (name: string, index: number) => {
    if(isSM) return;
    setCheckId( name );
    componentScrolls(index);
  }
  
  const componentScrolls = (index: number) => {
    let parent = document.getElementById("wayward-customer_box");
    if(!parent) return;
    let suns = document.querySelectorAll<HTMLDivElement>(".testimonial-stacks");
    let width = parent.offsetWidth;
    let sun = suns[index];
    let sun_width = sun.offsetWidth;
    let sun_left = sun.offsetLeft;
    let centerX = width / 2 - sun_width / 2;
    let scrollLeft = sun_left - centerX;
    if(scrollLeft < 0) scrollLeft = 0;
    parent.scrollTo({ left: scrollLeft, behavior: "smooth" });
  }

  const createdScroll = () => {
    let parent = document.getElementById("wayward-customer_box");
    if(!parent) return;
    let scroll_w = parent.scrollWidth;
    let offset_w = parent.offsetWidth;
    let scroll_l = (scroll_w - offset_w) / 2;
    if(isSM) scroll_l = 0;
    parent.scrollLeft = scroll_l;
  }

  const swiperCreated = () => {
    var mySwiper = new window.Swiper('.swiper-customer', {
      spaceBetween: 30,
      initialSlide: 1,
      pagination: {
        el: '.swiper-customer-pagination',
        clickable: true,
      },
      on: {
        slideChange: function(){
          let _t = this as any;
          let index = _t.activeIndex;
          setCheckId(customerList[index].name);
        }
      }
    });
    swiperRef.current = mySwiper;
  }

  useEffect(()=>{
    createdScroll();
    if(isSM) swiperCreated();
    return () => {
      swiperRef.current?.destroy();
    }
  },[isSM]);


  return (
    <Stack sx={{
      alignItems: "center", maxWidth: "19.2rem", m: isSM ? "0.55rem auto 0" : "1.7rem auto 0",
    }}>
      <Typography sx={{
        fontFamily: "ClashDisplay", textAlign: "center",
        fontSize: isSM ? "0.32rem" : "0.76rem",
        lineHeight: isSM ? "0.32rem" : "0.76rem"
      }} component="h2">
        Customer Testimonial
      </Typography>
      <Box overflow="auto" width="100%"
        p={isSM ? "0.66rem 0.2rem 0.5rem" : "1.26rem 0 0"}
        sx={{
          position: "relative",
          "&::-webkit-scrollbar": { height: "0" },
          "&::-webkit-scrollbar-thumb": { background: "transparent" },
          "&::-webkit-scrollbar-track": { background: "transparent" },
        }} id="wayward-customer_box" className="swiper-customer">
        <Stack direction="row"
          gap={isSM ? "0" : "0.55rem"}
          width={isSM ? "100%" : "fit-content" }
          p={isSM ? "0" : "0 0.5rem"}
          className="swiper-wrapper">
          {customerList.map((item,index)=>(
            <Stack key={item.name} sx={{
              flexShrink: 0,
              position: "relative",
              width: isSM ? "100%" : "6.5rem",
              height: isSM ? "4.2rem" : "5.2rem",
              p: isSM ? "0.64rem 0.28rem 0.16rem" : "1.08rem 0.48rem 0.35rem",
              backgroundImage: checkId === item.name ? "linear-gradient(280deg, #5034E9 -8%, #9B8BF9 113%)" : "url(/eurekaImage/customer_bg.png)",
              backgroundSize: "100% 100%",
              borderRadius: "0.36rem",
              cursor: "pointer",
              boxSizing: "border-box",
            }} onClick={ ()=> customerChange(item.name, index) }
            className="testimonial-stacks swiper-slide">
              <Box sx={{
                position: "absolute", top: isSM ? "-0.24rem" : "-0.4rem",
                left: isSM ? "0.3rem" : "0.5rem",
              }}>
                <QuotationMarksIcon fill={checkId === item.name ? "#FFFFFF" : "#7761F1"}
                  sx={{
                    width: isSM ? "0.55rem" : "0.92rem",
                    height: isSM ? "0.46rem" : "0.77rem"
                  }} />
              </Box>
              <Box flexGrow="1">
                <Typography sx={{
                  fontFamily: "Satoshi", 
                  fontSize: isSM ? "0.16rem" : "0.2rem",
                  lineHeight: isSM ? "0.24rem" : "0.3rem",
                }}>
                  {item.desc}
                </Typography>
              </Box>
              <Stack direction="row" sx={{
                borderTop: "0.01rem solid #C3E4F3", pt: "0.3rem",
                alignItems: "center"
              }}>
                <CardMedia component="img" src={item.url}
                  sx={{
                    width: isSM ? "0.56rem" : "0.9rem", height: isSM ? "0.56rem" : "0.9rem",
                    borderRadius: "50%"
                  }} alt="User Evaluate" />
                <Box ml={isSM ? "0.2rem" : "0.36rem"}>
                  <Typography sx={{
                    fontSize: isSM ? "0.16rem" : "0.2rem",
                    lineHeight: isSM ? "0.24rem" : "0.3rem"
                  }}>
                    {item.name}
                  </Typography>
                  <Typography sx={{
                    fontSize: isSM ? "0.16rem" : "0.2rem",
                    lineHeight: isSM ? "0.24rem" : "0.3rem",
                    fontWeight: "300"
                  }}>
                    {item.brand}
                  </Typography>
                </Box>
                <Box ml="auto">
                  <Rating name="read-only" value={5} readOnly
                    sx={{ fontSize: isSM ? "0.16rem" : "0.24rem", color: "#FFFFFF" }} />
                </Box>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Box className="swiper-customer-pagination" sx={{
          position: "absolute", left: "0",
          display: isSM ? "flex" : "none", justifyContent: "center",
          "& .swiper-pagination-bullet-active": {
            transform: "scale(1.5)",
            background: "rgb(125,105,239) !important",
          },
          "& .swiper-pagination-bullet": {
            background: "rgb(129,131,153)",
            opacity: "1",
          }
        }}></Box>
      </Box>
    </Stack>
  )
}