import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../store';

export type ToolSliceType = {
  planOpen: boolean;
  upgradeOpen: boolean;
  cropId: string;
  uploadProgress: { progress: number; intervas: number; } | null;
}

const initialState: ToolSliceType = {
  planOpen: false,
  upgradeOpen: false,
  cropId: '',
  uploadProgress: null,
};

const slice = createSlice({
  name: 'Tool',
  initialState,
  reducers: {
    setPlanOpen(state: ToolSliceType, action: PayloadAction<boolean>) {
      state.planOpen = action.payload;
    },
    setUpgradeOpen(state: ToolSliceType, action: PayloadAction<boolean>) {
      state.upgradeOpen = action.payload;
    },
    setCropId(state: ToolSliceType, action: PayloadAction<string>) {
      state.cropId = action.payload;
    },
    setUploadProgress(
      state: ToolSliceType,
      action: PayloadAction<{ progress: number; intervas: number; } | null>
    ) {
      state.uploadProgress = action.payload;
    },
  },
});

export const {
  setPlanOpen, setUpgradeOpen, setCropId,
  setUploadProgress
} = slice.actions;

export const selectTool = createSelector(
  [(state: RootState) => state.tool],
  (state) => state
);

export default slice.reducer;