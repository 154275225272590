import { selectAuth } from '../redux/slice/AuthSlice';
import { useTypedSelector } from '../redux/store';

export function useRegisterInfo() {
  const { registerInfo } = useTypedSelector((state) => selectAuth(state));
  return registerInfo;
};
export function useGetSessionCookies(){
  const { access_token } = useTypedSelector((state) => selectAuth(state));
  return access_token;
};

export function useGetRefreshToken(){
  const { refresh_token } = useTypedSelector((state) => selectAuth(state));
  return refresh_token;
};
export function useGetUser(){
  const { user } = useTypedSelector((state) => selectAuth(state));
  return user;
}
export function useGetrefreshTokenExpireAt(){
  const { refresh_token_expire_at } = useTypedSelector((state) => selectAuth(state));
  return refresh_token_expire_at;
};

export function useGetMaxmuiNumber(){
  const { user } = useTypedSelector((state) => selectAuth(state));
  return user?.sufficient_quota || false;
}
export function useGetUserPlan(){
  const { user } = useTypedSelector((state) => selectAuth(state));
  return user?.current_plan || "free";
}
