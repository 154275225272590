import { Box, Link, Stack, Typography, styled } from "@mui/material";
import { useMemo } from "react";
import {
  FaceBookIcon, InstagramIcon, LinkedInIcon, PinterestIcon, TwitterIcon
} from "../../assets/imgs/logoIcons";
import { useBreakpointSm } from "../../utils/useStyleHooks";

const SpanBox = styled("span")({
  fontSize: "0.18rem", fontFamily: "Satoshi",
  lineHeight: "0.24rem", fontWeight: "400",
  color: "#FFFFFF",
});

export default function EurekaFooder(){
  const isSM = useBreakpointSm();
  const footer_links = [
    {name: "Home", url: "/"},
    {name: "Features", url: "/#how-it-works"},
    {name: "Pricing", url: "/#pricing"},
    {name: "Resources", url: "/#resources"},
  ];
  const linkIconList = useMemo(()=>[
    {icon: <FaceBookIcon fill="#FFFFFF" sx={{ fontSize: "0.18rem" }} />, id: "facebook"},
    {icon: <PinterestIcon fill="#FFFFFF" sx={{ fontSize: "0.24rem" }} />, id: "pinterest"},
    {icon: <LinkedInIcon fill="#FFFFFF" sx={{ fontSize: "0.18rem" }} />, id: "linkedin"},
    {icon: <InstagramIcon fill="#FFFFFF" sx={{ fontSize: "0.22rem" }} />, id: "instagram"},
    {icon: <TwitterIcon fill="#FFFFFF" sx={{ fontSize: "0.2rem" }} />, id: "twitter"},
  ],[]);
  return (
    <Box sx={{
      pt: "0.2rem",
      backgroundImage: isSM ? "none" : "url(/eurekaImage/footerbg.png)",
      backgroundSize: "100%",
      maxWidth: "19.2rem", m: "0.68rem auto 0",
    }} component="footer">
      {/* <Stack direction="row" justifyContent="space-between" alignItems="center"
        sx={{
          width: "15.9rem", m: "0 auto",
        }}>
        <Stack direction="row" gap="1rem">
          {footer_links.map((link, index) => (
            <Typography key={link.name} sx={{
              color: index === 0 ? "#05C3B8" : "#FFFFFF",
              fontSize: "0.18rem", fontFamily: "Satoshi",
            }}>
              {link.name}
            </Typography>
          ))}
        </Stack>
        <Stack direction="row" gap="0.2rem">
          {linkIconList.map((item, index) => (
            <Stack justifyContent="center" alignItems="center" key={item.id}
              sx={{
                width: "0.48rem", height: "0.48rem", cursor: "pointer",
                borderRadius: "50%", border: "0.02rem solid #FFFFFF"
              }}>
              {item.icon}
            </Stack>
          ))}
        </Stack>
      </Stack> */}
      <Box width="100%" height={isSM ? "0.5rem" : "2.8rem"}></Box>
      <Stack direction={isSM ? "column" : "row"} sx={{
        p: "0.4rem 0 0.42rem", justifyContent: "space-between",
        alignItems: isSM ? "center" : "flex-start",
        borderTop: "0.01rem solid #FFFFFF",
        width: isSM ? "100%" : "15.9rem",
        m: "0 auto",
        backgroundImage: !isSM ? "none" : "url(/eurekaImage/footerbg.png)",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}>
        <SpanBox>
          © 2024 Wavward. All right reserved.
        </SpanBox>
        <Stack direction="row" gap={isSM ? "0.18rem" : "0.66rem"} 
          alignItems={isSM ? "center" : "flex-start"}
          mt={isSM ? "0.2rem" : "0"}>
          <Link href="https://www.wayward.com/terms"
            target="_blank">
            <SpanBox>Terms & Condition</SpanBox>
          </Link>
          <Link href="https://www.wayward.com/privacy-policy"
            target="_blank">
            <SpanBox>Privacy Policy</SpanBox>
          </Link>
        </Stack>
      </Stack>
    </Box>
  )
}
