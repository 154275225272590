import { Box, CardMedia, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { marketings } from "./aTemplate";
import { useBreakpointSm } from "../../utils/useStyleHooks";

export default function EurekaMarketing(){
  const isSM = useBreakpointSm();
  const [checked, setChecked] = useState(0);
  const marketingList = useMemo(()=> marketings,[]);

  const updateCheckedChange = (index:number)=>{
    setChecked(index);
  }

  return (
    <Stack sx={{
      alignItems: "center", maxWidth: "19.2rem",
      m: isSM ? "0.6rem auto 0" : "1.86rem auto 0",
    }}>
      <Typography sx={{
        fontFamily: "ClashDisplay", textAlign: "center",
        fontSize: isSM ? "0.32rem" : "0.76rem",
        lineHeight: isSM ? "0.32rem" : "0.76rem",
      }} component="h2">
        Why This Will Transform
      </Typography>
      <Typography sx={{
        fontFamily: "ClashDisplay", textAlign: "center",
        fontSize: isSM ? "0.32rem" : "0.76rem",
        lineHeight: isSM ? "0.32rem" : "0.76rem",
      }} component="h2">
        <span>Your </span><span style={{ color: "#705AEF" }}>Marketing</span>
      </Typography>
      <Stack direction={isSM ? "column" : "row"}
        mt={isSM ? "0.5rem" : "1.2rem"} gap="0.5rem">
        {marketingList.map((item,index)=>(
          <Box key={index} sx={{
            width: isSM ? "3.2rem" : "3.7rem",
            height: isSM ? "3.4rem" : "4.1rem", position: "relative",
            backgroundImage: checked === index ? "linear-gradient(280deg, #5034E9 -8%, #9B8BF9 113%)" : "url(/eurekaImage/marketing_bg.png)",
            backgroundSize: "100% 100%",
            p: isSM ? "1.4rem 0.3rem " : "1.54rem 0.3rem 0", cursor: "pointer",
            borderRadius: "0.36rem"
          }} onClick={ ()=> updateCheckedChange(index) }>
            <CardMedia component="img" src={item.url}
              sx={{
                position: "absolute", top: item.top, left: "0.3rem",
                width: item.width, zIndex: 1,
              }} alt="Eureka AI Function" />
            <Box sx={{
              width: "0.4rem", height: "0.4rem",
              borderRadius: "0.2rem",
              position: "absolute", top: "0.8rem", left: "0.2rem",
              background: checked === index ? "#FFFFFF" : "#5437D7",
              filter: "blur(0.1rem)",
            }}></Box>
            <Typography sx={{
              fontSize: isSM ? "0.24rem" : "0.3rem",
              lineHeight: isSM ? "0.3rem" : "0.38rem",
              fontWeight: "700"
            }}>
              {item.name}
            </Typography>
            <Typography sx={{
              fontFamily: "Satoshi", mt: "0.1rem",
              fontSize: isSM ? "0.16rem" : "0.2rem",
              lineHeight: isSM ? "0.22rem" : "0.28rem",
            }}>
              {item.desc}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}