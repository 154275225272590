import { Box, CardMedia, Stack, Typography, styled } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import { EGradientTxt } from "./componted";
import { useBreakpointSm } from "../../utils/useStyleHooks";
import { slideshowsList } from "./aTemplate";

const DivBox = styled("div")({})

export default function EurekaDynamic(){

  const isSM = useBreakpointSm();
  const swiperRef = useRef<any | null>(null);
  const socialList = useMemo(()=>[
    {id: "1", text: "Our AI analyzes your product page and creates stunning slideshows that highlight key features and benefits." },
    {id: "2", text: "Perfect for social media, website banners, and email campaigns." },
    {id: "3", text: "Customize with your branding and add calls to action" },
  ],[]);

  const imageList = useMemo(()=> slideshowsList,[]);

  const createSwiper = () => {
    
    var swiper = new window.Swiper(".dynamic-swiper", {
      slidesPerView: "auto",
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    swiperRef.current = swiper;
  }


  useEffect(() => {
    createSwiper();
    return () => {
      swiperRef.current?.destroy();
    }
  }, [isSM]);

  return (
    <Box sx={{
      width: isSM ? "3.9rem" : "16.2rem",
      m: isSM ? "1rem auto 0" : "2.4rem auto 0", position: "relative",
    }}>
      <Box sx={{
        position:"absolute", zIndex:"0",
        width: isSM ? "0.8rem" : "1.3rem", height: isSM ? "0.8rem" : "1.3rem",
        left: isSM ? "0.4rem" : "0.6rem", top: isSM ? "0.6rem" : "1.6rem",
        background: "#5D49D2", borderRadius: "50%"
      }}></Box>
      <Stack direction={isSM ? "column" : "row"}
        sx={{
          position: "relative", borderRadius: "0.36rem", zIndex: 1,
          background: "linear-gradient(170deg, rgba(38, 44, 120, 0.41) 12%, rgba(25, 39, 86, 0.41) 100%)",
          backdropFilter: "blur(0.45rem)"
        }}>
        <Box sx={{
          p: isSM ? "0.66rem 0 0" : "1.45rem 0.58rem 1.45rem 1.08rem",
          width: isSM ? "100%" : "8.5rem", flexShrink: 0
        }}>
          {isSM ? (
            <Typography sx={{
              fontFamily: "ClashDisplay", fontSize: "0.32rem", lineHeight:"0.32rem",
              letterSpacing: "-0.1px", ml: "0.16rem"
            }} component="h2">
              <span>Dynamic </span><EGradientTxt>Slideshows </EGradientTxt><br />
              <span>That Drive Engagement</span>
            </Typography>
          ):(
            <Typography sx={{
              fontFamily: "ClashDisplay", fontSize: "0.76rem", lineHeight:"0.76rem",
              letterSpacing: "-0.1px"
            }} component="h2">
              <span>Dynamic</span><br />
              <EGradientTxt>Slideshows </EGradientTxt><span>That</span><br />
              <span>Drive Engagement</span>
            </Typography>
          )}
          
          <Box mt="0.4rem" position="relative" p={isSM ? "0 0.28rem" : ""}>
            <Box sx={{
              position: "absolute", top: "0.06rem", left: isSM ? "0.39rem" : "0.15rem",
              width: "0.01rem", height: "100%", bgcolor: "#8A8BA3"
            }}></Box>
            {socialList.map((item,index)=>(
              <Stack key={item.id} direction="row" alignItems="flex-start"
                pb={isSM ? "0.26rem" : "0.34rem"} position="relative" zIndex="1">
                <Box p="0.05rem" bgcolor="#FFFFFF38" borderRadius="50%">
                  <Box sx={{
                    width: isSM ? "0.12rem" : "0.2rem", height: isSM ? "0.12rem" : "0.2rem",
                    borderRadius: "0.1rem",
                    background: "linear-gradient(285deg, rgb(142,124,245), rgb(112,89,239))"
                  }}></Box>
                </Box>
                <Box sx={{ ml: isSM ? "0.14rem" : "0.2rem" }}>
                  <Typography sx={{
                    fontFamily: "Satoshi",
                    fontSize: isSM ? "0.16rem" : "0.2rem",
                    lineHeight: isSM ? "0.24rem" : "0.3rem", 
                  }}>
                    {item.text}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Box>
        </Box>

        <Box sx={{
          flexGrow: "1", overflow: "hidden", p: isSM ? "0.4rem" : "1.45rem 0 0 0"
        }}>
          <Box sx={{
            position: "relative", pb: isSM ? "0.4rem" : "0.6rem",
            "& .swiper-pagination-bullet":{
              background: "rgb(140,144,167)",
              opacity: 1
            },
            "& .swiper-pagination-bullet-active":{
              background: "#806CF2",
              transform: "scale(2)"
            },
          }}>
            <DivBox className="dynamic-swiper" sx={{
              overflow: isSM ? "hidden" : "visible",
            }}>
              
              <DivBox className="swiper-wrapper">
                {imageList.map((item,index)=>(
                  <DivBox key={item.id} className="swiper-slide"
                    sx={{
                      width: isSM ? "100%" : "auto",
                      borderRadius: "0.2rem", overflow: "hidden"
                    }}>
                    <CardMedia component="img" src={item.url}
                      sx={{
                        height: isSM ? "3.2rem" : "5.12rem", width: "auto"
                      }} alt="Eureka Slideshow Swiper" />
                  </DivBox>
                ))} 
              </DivBox>
            </DivBox>
            <DivBox className="swiper-pagination" sx={{
              width: isSM ? "100%" : "4.9rem !important",
            }}></DivBox>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

