import {
  Box, IconButton, Stack, Typography,
  SxProps, Theme
} from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FilterIcon from '@mui/icons-material/Filter';

import {
  AvatarIcon, CreateSquareIcon, PagePhoneMoresIcon, PlayPageLogoIcon,
  ProductsFolderIcon, VideoFolderIcon
} from "../../assets/imgs/icons";
import { ButtonText } from "../../components/muiCustom/Button";
import { useGetUser } from "../../hooks/userHook";

export default function PageNavgationPhone({
  upgradeOpen
}:{
  upgradeOpen: (value: boolean) => void;
}){
  const navigate = useNavigate();
  const userInfo = useGetUser();
  const [openSelect, setOpenSelect] = useState(false);

  const artchRef = useRef<HTMLDivElement>(null);
  const checked = window.location.pathname;
  const checkRouteList = [
    "/", "/videos", "/avatars", "/product-folder", "/slideshow",
    "/account-user"
  ];
  if( !checkRouteList.includes(checked) ){
    window.location.replace("/");
  }

  const routerReplace = (url: string)=>{
    setOpenSelect(false);
    navigate(url, { replace: true });
  }
  const createSquare = useCallback(()=>{
    if(!userInfo?.sufficient_quota){ upgradeOpen(true); return; }
    navigate("/create-video");
  },[]);

  const HandleBox = useCallback(({
    icon,
    text,
    routed,
    onClick,
  }:{
    icon: React.ReactNode,
    text: string,
    routed: string,
    onClick?: ()=>void
  })=>{
    
    return (
      <Stack width="100%" direction="row" p="0.15rem 0.16rem" gap="0.12rem"
        sx={{
          cursor: "pointer", borderRadius: "0.12rem",
          color: checked === routed ? "#fff" : "#858b95",
          bgcolor: checked === routed ? "#FFFFFF1A" : "transparent",
          "&:hover": { bgcolor: "#FFFFFF1A" }
        }}
        onClick={ ()=> { onClick && onClick() }  }>
        {icon}
        <Typography sx={{ color: "#FFFFFF", fontSize: "0.16rem" }}>
          {text}
        </Typography>
      </Stack>
    )
  },[checked]);

  const styleSelect: {
    [key: string]: SxProps<Theme>
  } = useMemo(()=>{
    let obj: {[key: string]: SxProps<Theme>} = {
      total: { width: "100%", position: "relative", bgcolor: "#FFFFFF1A" },
      total_txt: { fontSize: "0.18rem", lineHeight: "0.24rem", cursor: "pointer" }
    }
    return obj
  },[]);

  return (
    <Stack sx={styleSelect.total}>
      <Stack width="100%" direction="row" gap="0.12rem" alignItems="center">

        <Stack direction="row" gap="0.12rem" alignItems="center" p="0.2rem">
          <PlayPageLogoIcon sx={{ fontSize: "0.24rem" }} />
          <Typography sx={styleSelect.total_txt} onClick={ ()=> routerReplace("/") }>
            Eureka
          </Typography>
        </Stack>
        { openSelect ? (
          <ButtonText onClick={ ()=> setOpenSelect(false) }
            sx={{ p: "0.22rem", ml: "auto", gap: "0.16rem" }}>
            <Typography fontSize="0.16rem" lineHeight="0.2rem">Close Menu</Typography>
            <CloseRoundedIcon sx={{ color: "#FFF", fontSize:"0.2rem" }} />
          </ButtonText>
        ) : (
          <>
            <ButtonText onClick={ ()=> routerReplace("/") }
              sx={{ p: "0.22rem 0 0.22rem 0.22rem", ml: "auto" }}>
              <Typography fontSize="0.16rem" lineHeight="0.2rem">
                Home
              </Typography>
            </ButtonText>
            <IconButton onClick={ ()=> setOpenSelect(true) }
              sx={{ p: "0.22rem 0.22rem 0.22rem 0" }}>
              <PagePhoneMoresIcon sx={{ fontSize:"0.2rem" }} />
            </IconButton>
          </>
        ) }
      </Stack>
      <Box position="fixed" sx={{
        display: openSelect ? "block" : "none",
        left: 0, top: "0.64rem", width: "100%", padding: "0.26rem 0.2rem",
        height: "calc(100% - 0.6rem)", zIndex: "999", background: "#0E173199",
        backdropFilter: "blur(60px)"
      }}>
        <Stack bgcolor="#FFFFFF1A" sx={{ borderRadius: "0.16rem", height: "100%", padding: "0.16rem" }}>
          <HandleBox icon={<CreateSquareIcon sx={{ fontSize:"0.2rem" }} />}
            text="Create" routed="/create-video" onClick={createSquare} />
          <Box sx={{ width: "100%", height: "0.01rem", bgcolor: "#ECEFF026", m: "0.12rem 0" }}></Box>
          <HandleBox icon={<VideoFolderIcon sx={{ fontSize:"0.2rem" }} />}
            text="Videos" routed="/videos" onClick={ ()=> routerReplace("/videos") } />
          {/* <HandleBox icon={<FilterIcon sx={{ fontSize:"0.2rem" }} />}
            text="Slideshow" routed="/slideshow" onClick={ ()=> routerReplace("/slideshow") } /> */}
          <HandleBox icon={<AvatarIcon sx={{ fontSize:"0.2rem" }} />}
            text="Avatars" routed="/avatars" onClick={ ()=> routerReplace("/avatars") } />
          <HandleBox icon={<ProductsFolderIcon sx={{ fontSize:"0.2rem" }} />}
            text="Products" routed="/product-folder" onClick={ ()=> routerReplace("/product-folder") } />
          <Box flexGrow={1}></Box>
          <Box ref={artchRef}>
            <HandleBox icon={<AccountCircleOutlinedIcon sx={{ fontSize:"0.24rem", color: "#FFFFFF" }} />}
              text="Account" routed="/account-user" onClick={ ()=> routerReplace("/account-user") } />
          </Box>
        </Stack>
      </Box>
      
    </Stack>
  )
}