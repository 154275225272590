import {
  Button as MuiButton,
  ButtonProps,
  SxProps,
  styled
} from "@mui/material";

// export type ButtonProps = {
//   sx?: SxProps;
//   children: React.ReactNode;
//   onClick?: () => void;
// }
export const ButtonText = styled(({...props}: ButtonProps)=>(
  <MuiButton variant="text" {...props} />
))({
  borderRadius:"0.5rem",
  "&:hover": { backgroundColor: "transparent" }
});
// color="primary"
export const ButtonPrimary = styled(({...props}: ButtonProps)=>{
  return <MuiButton variant="contained" {...props} />
})({
  borderRadius:"0.12rem",
});

export const PromptButton = styled(MuiButton)(({ theme }) => ({
  color: "#131313", borderRadius: "0.8rem", fontSize: "14px", fontWeight: 400, minWidth: "20px",
  "&:hover": { backgroundColor: "transparent" }
}));

export const ProductBlackButton = styled(({...props}: ButtonProps)=>(
  <MuiButton {...props} />
))({
  color: "#828282", backgroundColor: "#F5F5F5", borderRadius: "0.625rem", fontSize: "14px", fontWeight: 400,
  padding: "0.5rem 0.75rem",
  border: "1px solid #E0E0E0",
  "&:hover": { backgroundColor: "#E5E5E5" },
  "&:disabled": {
    backgroundColor: "#131313", color: "#FFFFFF",
    borderColor: "#131313"
  }
});

export const ButtonOpacity = styled(MuiButton)({
  backgroundColor: "transparent",
  borderRadius:"0.06rem",
  border:"1px #4C5361 solid",
  color: "#FFFFFF",
  "&:hover": { backgroundColor: "#4C5361" },
})

export const ButtonOpacityWhile = styled(MuiButton)({
  backgroundColor: "transparent",
  borderRadius:"0.12rem",
  border:"1px #FFFFFF solid",
  color: "#FFFFFF",
  "&:hover": { backgroundColor: "#4C5361" },
})

export const ButtonInfo = styled(({...props}: ButtonProps)=>(
  <MuiButton {...props} />
))({
  color: "#FFFFFF", backgroundColor: "#595959", borderRadius: "0.08rem",
  "&:hover": { backgroundColor: "#595959" },
  "&:disabled": {
    backgroundColor: "#131313", color: "#888",
  }
});

export const ButtonWhile = styled(({...props}: ButtonProps)=>(
  <MuiButton {...props} />
))({
  color: "#000", backgroundColor: "#FFFFFF", borderRadius: "0.12rem",
  "&:hover": { backgroundColor: "#CCC" },
  "&:disabled": {
    backgroundColor: "#131313", color: "#888",
  }
});

export const ButtonWhileTen = styled(({...props}: ButtonProps)=>(
  <MuiButton {...props} />
))({
  color: "#FFFFFF", backgroundColor: "#FFFFFF1A", borderRadius: "0.12rem",
  "&:hover": { backgroundColor: "#FFFFFF3C" },
  "&:disabled": {
    backgroundColor: "#131313", color: "#888",
  }
});

export const ButtonSquareBlack = styled(({...props}: ButtonProps)=>(
  <MuiButton {...props} />
))({
  color: "#FFFFFF", backgroundColor: "#353E59", borderRadius: "0.1rem",
  minWidth: "0", padding: "0.1rem",
  "&:hover": { backgroundColor: "#455277" },
  "&:disabled": {
    backgroundColor: "#131313", color: "#888",
  }
});


