import { Box, CardMedia, Stack, Typography } from "@mui/material";

export default function StepTags({
  direction,
  data,
  icon,
  isSM
}:{
  direction: "left" | "right";
  data: {id: string, name: string, desc: string, url: string};
  icon: React.ReactNode;
  isSM: boolean;
}){
  return (
    <Stack direction={ direction === "right" || isSM ? "row" : "row-reverse" }
      id={ "eureka-pagemodal-" + data.id }
      sx={{ position: "relative", zIndex: "1" }}>
      <Box sx={{
        overflow: "hidden", flex: "1", display: isSM ? "none" : "block",
      }}>
        <CardMedia component="img" image={data.url} sx={{
          maxHeight: "3.56rem",
          maxWidth: "5.67rem", borderRadius: "0.24rem",
        }} />
      </Box>
      <Stack sx={{ alignItems: "center", p: isSM ? "0 0.18rem 0 0" : "0 0.46rem" }}>
        <Box mt="0.1rem" sx={{
          p: "0.05rem", borderRadius: "0.2rem", bgcolor: "#CFC9FF59",
        }}>
          <Box sx={{
            width: "0.18rem", height: "0.18rem",
            borderRadius: "0.2rem",
            background: "linear-gradient(90deg, #5688ce 0, #20aebe 100%)"
          }}></Box>
        </Box>
      </Stack>
      <Box sx={{ flex: "1" }}>
        <Stack direction={ direction === "right" || isSM ? "row-reverse" : "row" }>
          <Box flexGrow="1"></Box>
          <Typography component="span" sx={{
            background: "linear-gradient(135deg, rgb(14,193,200) 0, rgb(32,153,219) 100%)",
            fontFamily: "ClashDisplay", fontSize: "0.24rem",
            letterSpacing: "-0.1px", lineHeight: "0.24rem",
            p: "0.12rem 0.26rem",
            borderRadius: "0.5rem",
            boxShadow: "-0.05rem 0.1rem 0.6rem 0px #1587E8A1",
            textTransform: "capitalize",
          }}>
            {data.id}
          </Typography>
        </Stack>
        <Stack direction={ direction === "right" || isSM ? "row-reverse" : "row" }
          mt={ isSM ? "0.35rem" : "0.56rem"}>
          <Box flexGrow="1"></Box>
          {icon}
        </Stack>
        <Box sx={{
          mt: "0.22rem", ml: direction === "right" || isSM ? "0" : "auto",
          fontFamily: "ClashDisplay",
          fontSize: isSM ? "0.3rem" : "0.46rem",
          lineHeight: isSM ? "0.3rem" : "0.46rem",
          color: "#0C152D", fontWeight: "500",
          maxWidth: "4.6rem", textAlign: direction === "right" || isSM ? "left" : "right"
        }}>
          {data.name}
        </Box>
        <Box sx={{
          mt: "0.14rem", ml: direction === "right" || isSM ? "0" : "auto",
          fontFamily: "Satoshi",
          fontSize: isSM ? "0.16rem" : "0.18rem",
          lineHeight: isSM ? "0.18rem" : "0.26rem",
          color: "#464646", fontWeight: "500",
          maxWidth: isSM ? "3.04rem" : "4.8rem",
          textAlign: direction === "right" || isSM ? "left" : "right"
        }}>
          {data.desc}
        </Box>
        {isSM && (
          <CardMedia component="img" image={data.url} sx={{
            mt: "0.3rem", width: "3.04rem", borderRadius: "0.24rem",
          }} />
        )}
      </Box>
    </Stack>
  )
}