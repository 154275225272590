import { Box, CardMedia, Link, Stack, Typography } from "@mui/material";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useBreakpointSm } from "../../utils/useStyleHooks";

export default function EurekaModuleEig({
  openFormEmbed
}:{
  openFormEmbed: ()=>void
}){
  const isSM = useBreakpointSm();

  return (
    <Box sx={{
      maxWidth: "16.2rem",
      m: isSM ? "0.8rem auto 0" : "2.86rem auto 0", position: "relative",
      p: isSM ? "0 0.2rem" : "0"
    }}>
      <Box sx={{
        position:"absolute", zIndex:"0",
        width:"4rem", height:"4rem", right:"2rem", top:"0rem",
        background: "#5D49D2", filter: "blur(2rem)"
      }}></Box>
      <Stack direction="row" sx={{
        borderRadius: "0.36rem", position: "relative", zIndex: "1",
        background: "linear-gradient(280deg, #5034E9 -9%, #9B8BF9 113%)",
        overflow: isSM ? "hidden" : "visible",
      }}>
        <Box sx={{
          width: isSM ? "100%" : "8.9rem",
          p: isSM ? "0.6rem 0.2rem 0 0.3rem" : "1.36rem 0 1.36rem 0.96rem",
          height: isSM ? "7rem" : "6.2rem"
        }}>
          <Typography sx={{
            fontFamily: "ClashDisplay",
            fontSize: isSM ? "0.32rem" : "0.76rem",
            lineHeight: isSM ? "0.32rem" : "0.76rem", 
          }} component="h2">
            Ready to experience the future of product marketing?
          </Typography>
          <Stack direction="row" mt={isSM ? "0.2rem" : "0.34rem"}>
            <Stack direction="row" sx={{
              alignItems: "center", p: isSM ? "0.18rem 0.22rem" : "0.25rem 0.28rem",
              backgroundImage: "url(/eurekaImage/pageStarted3.png)",
              backgroundSize: "100% 100%", cursor: "pointer",
              filter: "drop-shadow(-0.08rem 0.09rem 0.48rem #7422D8B5)"
            }} onClick={openFormEmbed}>
              <Typography sx={{
                fontFamily: "Satoshi", fontSize: isSM ? "0.14rem" : "0.18rem",
                lineHeight: isSM ? "0.14rem" : "0.18rem", mr: "0.1rem",
                color: "#292C33"
              }}>
                Request Early Access
              </Typography>
              <ArrowForwardRoundedIcon sx={{ fontSize: "0.18rem", color: "#292C33" }} />
            </Stack>
          </Stack>
        </Box>
        <CardMedia component="img" src="/eurekaImage/questions1.png"
          sx={{
            position: "absolute", bottom: "0", right: "0",
            width: "auto", height: isSM ? "4.6rem" : "9rem",
          }} loading="lazy" alt="Eureka Mobile Fitter" />
        <Box sx={{
          position: "absolute", bottom: "0", left: "0",
          width: "100%", height: "2rem", pointerEvents: "none",
          borderRadius: "0.36rem",
          background: "linear-gradient(0deg, #725CEE 14%, rgba(0,0,0,0) 97%)"
        }}></Box>
      </Stack>
    </Box>
  )
}